import React, { CSSProperties } from "react";
import { FaAppleAlt, FaCandyCane, FaBaby } from "react-icons/fa";
import {
  GiBroom,
  GiCoffeeBeans,
  GiHamShank,
  GiMedicines,
  GiMilkCarton,
  GiPerfumeBottle,
  GiSlicedBread,
  GiWineBottle,
} from "react-icons/gi";
import { IoIosShirt, IoIosSnow } from "react-icons/io";
import { RiHome6Line, RiPlantLine, RiTempColdLine } from "react-icons/ri";
import { MdDevices } from "react-icons/md";
import { VscSymbolMisc } from "react-icons/vsc";
import { BLUE, GRAY, RED } from "./style_definitions";
import { CheckOutlined } from "@ant-design/icons";

export const DOMAIN = "ckal.dk";
export const API_ENDPOINT = `https://api.${DOMAIN}/madplanen/`;
export const BACKEND_VERSION_URL = `https://api.${DOMAIN}/version/madplanen`;

export const LF_CREDENTIALS = "madplanen_credentials";
export const LF_LAST_PATH = "last_path";
export const LF_STATISTICS = "stastistics";
export const LF_LAST_FOLDER = "last_folder";
export const LF_REFRESH_TARGET = "refresh_target";
export const LF_REFRESH_TARGET_DENY = "refresh_target_deny";

export const AWS_CLIENT_ID = "6nki0f24aj9hrvluekbmkea631";

export const CSE_API_KEY = "AIzaSyDhYhQywkSpEs49siq4_MeWdAk37uIz_UU";
export const CSE_ID = "7579fb426896f48f4";

export type Weekday = "Mandag" | "Tirsdag" | "Onsdag" | "Torsdag" | "Fredag" | "Lørdag" | "Søndag";

export enum APIResources {
  Items = "items",
  MyGroup = "my-group",
  Groups = "groups",
  ShoppingLists = "shopping-lists",
  Dishes = "dishes",
  WeekPlans = "week-plans",
  Folders = "folders",
  FolderContent = "folder-content",
}

export enum Category {
  Baby = "Baby",
  Home = "Bolig",
  Bread = "Brød & Bager",
  Beverages = "Drikkevarer",
  Electronics = "Elektronik",
  Frost = "Frost",
  Vegetables = "Frugt & Grønt",
  Garden = "Hus & Have",
  Colonial = "Kolonial",
  Meat = "Kød & Fisk",
  Medicine = "Medicin",
  Diary = "Mejeri",
  Beauty = "Personlig pleje",
  Cold = "På køl",
  Household = "Rengøring",
  Snacks = "Snacks",
  Textile = "Tekstil",
  Other = "Andet",
  Bought = "Købte",
}

export const categoryToColor: { [index in Category]: string } = {
  Baby: "#d7b693",
  Bolig: GRAY,
  "Brød & Bager": "#e2a75d",
  Drikkevarer: "#04afe4",
  Elektronik: "black",
  "Frugt & Grønt": "#8bc34a",
  Frost: "#91c2d2",
  Kolonial: "brown",
  "Kød & Fisk": "#c30000",
  "Hus & Have": "green",
  Medicin: GRAY,
  Mejeri: "#04afe4",
  "Personlig pleje": "purple",
  "På køl": "#04afe4",
  Rengøring: GRAY,
  Snacks: RED,
  Tekstil: GRAY,
  Andet: GRAY,
  Købte: GRAY,
};

export const categoryIconStyles: CSSProperties = {
  marginBottom: "1px",
};

export const categoryToIcon: { [index in Category]: any } = {
  Andet: <VscSymbolMisc style={categoryIconStyles} color={categoryToColor["Andet"]} />,
  Baby: <FaBaby style={categoryIconStyles} color={categoryToColor["Baby"]} />,
  Bolig: <RiHome6Line style={categoryIconStyles} color={categoryToColor["Bolig"]} />,
  "Brød & Bager": <GiSlicedBread style={categoryIconStyles} color={categoryToColor["Brød & Bager"]} />,
  Drikkevarer: <GiWineBottle style={categoryIconStyles} color={categoryToColor["Drikkevarer"]} />,
  Elektronik: <MdDevices style={categoryIconStyles} color={categoryToColor["Elektronik"]} />,
  Frost: <IoIosSnow style={categoryIconStyles} color={categoryToColor["Frost"]} />,
  "Frugt & Grønt": <FaAppleAlt style={categoryIconStyles} color={categoryToColor["Frugt & Grønt"]} />,
  "Hus & Have": <RiPlantLine style={categoryIconStyles} color={categoryToColor["Hus & Have"]} />,
  Kolonial: <GiCoffeeBeans style={categoryIconStyles} color={categoryToColor["Kolonial"]} />,
  "Kød & Fisk": <GiHamShank style={categoryIconStyles} color={categoryToColor["Kød & Fisk"]} />,
  Medicin: <GiMedicines style={categoryIconStyles} color={categoryToColor["Medicin"]} />,
  Mejeri: <GiMilkCarton style={categoryIconStyles} color={categoryToColor["Mejeri"]} />,
  "På køl": <RiTempColdLine style={categoryIconStyles} color={categoryToColor["På køl"]} />,
  "Personlig pleje": <GiPerfumeBottle style={categoryIconStyles} color={categoryToColor["Personlig pleje"]} />,
  Rengøring: <GiBroom style={categoryIconStyles} color={categoryToColor["Rengøring"]} />,
  Snacks: <FaCandyCane style={categoryIconStyles} color={categoryToColor["Snacks"]} />,
  Tekstil: <IoIosShirt style={categoryIconStyles} color={categoryToColor["Tekstil"]} />,
  Købte: <CheckOutlined style={categoryIconStyles} color={categoryToColor["Købte"]} />,
};

export const SEARCH_ITEM_LIST_HEIGHT = 300;

export const dishTypeToColor: { [k in string]: string } = {
  Morgen: "#6cbf9d",
  Middag: "#ec7237",
  Forret: "#d4cb64",
  Hovedret: RED,
  Dessert: BLUE,
};

export const LF_GROUP_ID = "groupId";
export const LF_CATEGORY_ORDER = "categoryOrder";

export enum Action {
  SelectDishForFolder = "SELECT_DISH_FOR_FOLDER",
  SelectFolderForDish = "SELECT_FOLDER_FOR_DISH",
  SelectDishForDay = "SELECT_DISH_FOR_DAY",
  SelectDayForDish = "SELECT_DAY_FOR_DISH",
}

export enum UpdateShoppingListAction {
  Add = "Add",
  Buy = "Buy",
  ClearBought = "ClearBought",
}

export type Unpacked<T> = T extends (infer U)[] ? U : T;

export const PLACEHOLDER_URL =
  "https://usercontent.one/wp/www.vocaleurope.eu/wp-content/uploads/no-image.jpg?media=1642546813";
