import React from "react";

interface LabelProps {
  text: string | undefined;
  color: string | undefined;
  padding?: string;
}

export function Label(props: LabelProps) {
  return (
    <div
      style={{
        background: props.color,
        color: "white",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: "normal",
        borderRadius: "2px",
        padding: props.padding || "4px 8px",
      }}
    >
      {props.text}
    </div>
  );
}
