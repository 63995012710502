import React, { useContext } from "react";
import { View } from "./view";
import { GroupContent } from "../settings/groupContent";
import { LIGHT_GRAY } from "../../lib/style_definitions";
import { AdminContent } from "../settings/adminContent";
import { CategoryOrderContent } from "../settings/categoryOrderContent";
import { Segment } from "../elements/segment";
import { Button } from "antd";
import { UserInfoContext } from "../../lib/contexts";
import { StatisticsContent } from "../settings/statisticsContent";
import { forceRefresh } from "../../lib/functions";

interface SettingsViewProps {
  onLogout(): void;
}

export function SettingsView(props: SettingsViewProps) {
  const { email } = useContext(UserInfoContext);

  return (
    <View scrollable header="Indstillinger">
      <Segment title="Gruppe">
        <GroupContent />
      </Segment>
      <Segment title="Kategorirækkefølge">
        <CategoryOrderContent />
      </Segment>
      <Segment title="Statistik">
        <StatisticsContent />
      </Segment>
      {email === "thisbecasper@gmail.com" && (
        <Segment title="Administration">
          <AdminContent />
        </Segment>
      )}
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Button size="large" onClick={props.onLogout}>
          Log ud
        </Button>

        <div style={{ marginTop: "48px", textAlign: "end", color: LIGHT_GRAY }}>{process.env.REACT_APP_VERSION}</div>
        <Button size="large" style={{ marginTop: "16px" }} onClick={forceRefresh}>
          Update WebApp
        </Button>
        <div style={{ minHeight: "200px" }} />
      </div>
    </View>
  );
}
