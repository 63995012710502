import React from "react";

interface SegmentProps {
  title?: string;
  children?: React.ReactNode;
  simple?: boolean;
}

export function Segment(props: SegmentProps) {
  return (
    <div style={{ marginBottom: "24px", width: "100%" }}>
      {props.title && (
        <div style={{ fontWeight: "bold", fontSize: "18px", margin: "0 0 4px 24px", color: "#656565" }}>
          {props.title.toUpperCase()}
        </div>
      )}
      <div
        style={{
          borderBottom: !props.simple ? "1px solid rgb(221, 225, 229)" : undefined,
          background: !props.simple ? "white" : undefined,
          width: "100%",
          padding: "24px",
          paddingTop: props.simple ? "8px" : "24px",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
