import React, { CSSProperties } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { BLUE } from "../../lib/style_definitions";

interface SpinnerProps {
  style?: CSSProperties;
}

export function Spinner(props: SpinnerProps) {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <LoadingOutlined
        style={{
          color: BLUE,
          fontSize: "36px",
          ...props.style,
        }}
      />
    </div>
  );
}
