import React, { CSSProperties } from "react";
import { CSSTransition } from "react-transition-group";
import { isOnMobile } from "../../lib/functions";

interface MModalProps {
  visible: boolean;
  content: any;
  onClose(): void;
  footer?: React.ReactNode;
  title?: string;
  style?: CSSProperties;
  className?: string;
}

export function MModal(props: MModalProps) {
  const { visible, onClose } = props;

  return (
    <>
      {visible && (
        <div className="mask" style={{ zIndex: isOnMobile() ? undefined : 5001 }}>
          <div
            className={"mmodal-cover " + (isOnMobile() ? "" : " mmodal-cover__widescreen ") + props.className}
            style={{
              ...props.style,
              width: isOnMobile() ? undefined : "100vw",
              height: isOnMobile() ? undefined : "100vh",
              left: isOnMobile() ? undefined : 0,
              top: isOnMobile() ? undefined : 0,
              zIndex: isOnMobile() ? undefined : 9001,
            }}
          >
            <div className={"mmodal" + (isOnMobile() ? "" : " mmodal__widescreen")}>
              {props.title && (
                <div style={{ marginBottom: "16px", fontWeight: "bold", color: "#636363" }}>
                  {props.title.toUpperCase()}
                </div>
              )}
              {props.content}
              {props.footer && <div style={{ marginTop: "48px" }}>{props.footer}</div>}
            </div>
            <div style={{ height: "112px" }} onClick={onClose} />
          </div>
          <CSSTransition timeout={200} classNames="fade-item">
            <div className="mask" onClick={onClose} />
          </CSSTransition>
        </div>
      )}
    </>
  );
}
