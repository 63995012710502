import React, { useEffect, useMemo, useState } from "react";
import { Item } from "../../lib/models";
import { VERY_LIGHT_GRAY } from "../../lib/style_definitions";
import { SearchOutlined } from "@ant-design/icons";
import { APIResources, Category, categoryToIcon } from "../../lib/definitions";
import { doFetch, doFormalize } from "../../lib/functions";
import { Button, Select } from "antd";
import { MModal } from "../elements/mmodal";
import { useContext } from "react";
import { DataContext } from "../../lib/contexts";
import { MInput } from "../elements/mInputs";
import { EcoLabel } from "../elements/ecoLabel";

export function AdminContent() {
  const [searchValue, setSearchValue] = useState("");
  const [selectedItem, setSelectedItem] = useState<Item>();
  const [renameValue, setRenameValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isUpdatingItem, setIsUpdatingItem] = useState(false);
  const [isDeletingItem, setIsDeletingItem] = useState(false);
  const [isEcologic, setIsEcologic] = useState(false);

  const { items, refreshItems } = useContext(DataContext);

  useEffect(() => {
    if (selectedItem) {
      setRenameValue(selectedItem.name);
      setSelectedCategory(selectedItem.category);
    }
  }, [selectedItem]);

  const filteredItems = useMemo(
    () =>
      items
        .filter((item) => doFormalize(item.name).includes(doFormalize(searchValue)))
        .sort((a, b) => doFormalize(a.name).localeCompare(doFormalize(b.name))),
    [items, searchValue]
  );

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
        <SearchOutlined style={{ marginRight: "8px", fontSize: "20px" }} />
        <MInput
          size="large"
          placeholder="Søg varer..."
          allowClear
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          className="new-item-input"
        />
      </div>
      <div
        style={{
          height: "224px",
          overflow: "auto",
          padding: "8px 16px",
          border: "1px solid " + VERY_LIGHT_GRAY,
          borderRadius: "4px",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "min-content auto min-content",
            columnGap: "16px",
            rowGap: "8px",
            fontSize: "18px",
            alignItems: "center",
          }}
        >
          {filteredItems.map((item) => (
            <React.Fragment key={item.name + item.isEcologic}>
              <div style={{ marginBottom: "-5px", marginRight: "4px" }}>{categoryToIcon[item.category]}</div>
              <div
                style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "normal" }}
                onClick={() => setSelectedItem(item)}
              >
                {item.name}
              </div>
              {item.isEcologic ? <EcoLabel size={1} /> : <div />}
            </React.Fragment>
          ))}
        </div>
      </div>
      <MModal
        visible={!!selectedItem}
        onClose={() => setSelectedItem(undefined)}
        title="Rediger vare"
        content={
          <div>
            <MInput size="large" value={renameValue} onChange={(e) => setRenameValue(e.target.value)} />
            <Select
              style={{ marginTop: "16px", width: "100%" }}
              options={Object.values(Category)
                .filter((cat) => ![Category.Bought, Category.Other].includes(cat))
                .map((cat) => ({ label: cat, value: cat }))}
              value={selectedCategory}
              onChange={(val) => setSelectedCategory(val)}
              size="large"
            />
            <div style={{ marginTop: "16px", display: "flex", justifyContent: "center" }}>
              <EcoLabel
                size={3}
                onClick={() => setIsEcologic(!isEcologic)}
                color={isEcologic ? undefined : "lightgray"}
              />
            </div>
          </div>
        }
        footer={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button size="large" style={{ marginRight: "8px" }} onClick={() => setSelectedItem(undefined)}>
              Luk
            </Button>
            <div style={{ display: "flex", justifyContent: "flex-end", columnGap: "8px" }}>
              <Button
                danger
                type="primary"
                size="large"
                loading={isDeletingItem}
                onClick={() => {
                  if (window.confirm(`Slet ${selectedItem?.name}?`)) {
                    setIsDeletingItem(true);
                    doFetch(
                      "DELETE",
                      APIResources.Items + "/" + selectedItem?._id,
                      () => {
                        refreshItems();
                        setSelectedItem(undefined);
                      },
                      (_, err) => alert(`Sletning af vare: ${err}`),
                      () => setIsDeletingItem(false)
                    );
                  }
                }}
              >
                Slet
              </Button>
              <Button
                size="large"
                type="primary"
                disabled={!renameValue}
                loading={isUpdatingItem}
                onClick={() => {
                  setIsUpdatingItem(true);
                  doFetch(
                    "PUT",
                    APIResources.Items + `/${selectedItem?._id}`,
                    () => {
                      refreshItems();
                      setSelectedItem(undefined);
                    },
                    () => alert("Opdatering af vare: Der skete en fejl"),
                    () => setIsUpdatingItem(false),
                    { body: { name: renameValue, category: selectedCategory, isEcologic: isEcologic } }
                  );
                }}
              >
                Opdater
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
}
