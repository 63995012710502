import React, { useContext } from "react";
import { DataContext } from "../../lib/contexts";
import { Action } from "../../lib/definitions";
import { getDateFromYearWeekDay, intToWeekday } from "../../lib/functions";
import { BLUE } from "../../lib/style_definitions";

interface CurrentActionBarProps {
  currentAction: { action: Action; data: any };
  onCancel(): void;
}

export function CurrentActionBar(props: CurrentActionBarProps) {
  const { refreshFolders } = useContext(DataContext);

  let text = "";

  if (props.currentAction.action === "SELECT_DISH_FOR_FOLDER") {
    text = `Tilføj retter til mappen ${props.currentAction.data.folderName}...`;
  } else if (props.currentAction.action === "SELECT_DISH_FOR_DAY") {
    text = `Tilføj ret til ${intToWeekday(props.currentAction.data.day).toLowerCase()} d. ${getDateFromYearWeekDay(
      props.currentAction.data,
      props.currentAction.data.day
    )}...`;
  } else if (props.currentAction.action === "SELECT_DAY_FOR_DISH") {
    text = `Vælg dag for ${props.currentAction.data.name}...`;
  } else if (props.currentAction.action === "SELECT_FOLDER_FOR_DISH") {
    text = `Vælg mappe for ${props.currentAction.data.name}`;
  }

  return (
    <div
      style={{
        position: "absolute",
        bottom: "94px",
        left: "40px",
        right: "40px",
        background: "white",
        boxShadow: "0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "12px 20px",
      }}
    >
      <div style={{ color: "#636363", marginRight: "12px" }}>{text}</div>
      <div
        onClick={() => {
          if (props.currentAction.action === "SELECT_DISH_FOR_FOLDER") {
            refreshFolders();
          }
          props.onCancel();
        }}
        style={{ fontWeight: "bold", color: BLUE }}
      >
        {props.currentAction.action === "SELECT_DISH_FOR_FOLDER" ? "FÆRDIG" : "LUK"}
      </div>
    </div>
  );
}
