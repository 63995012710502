import React, { useContext } from "react";
import { BsFolderMinus } from "react-icons/bs";
import { DataContext } from "../../lib/contexts";
import { dishTypeToColor, PLACEHOLDER_URL } from "../../lib/definitions";
import { isOnMobile } from "../../lib/functions";
import { Dish } from "../../lib/models";
import { Label } from "../elements/label";

interface DishCardProps {
  dish?: Dish;
  onClick(): void;
  onDeleteFromFolder?(): void;
  small?: boolean;
}

export function DishCard(props: DishCardProps) {
  const { currentAction } = useContext(DataContext);

  return (
    <div>
      <div
        style={{
          marginBottom: "8px",
          display: "grid",
          gridTemplateColumns: "auto min-content",
          columnGap: "4px",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            color: "#636363",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {props?.dish?.name.toUpperCase() || ""}
        </div>
        {props.onDeleteFromFolder && !currentAction ? (
          <BsFolderMinus style={{ fontSize: "20px", marginTop: "2px" }} onClick={props.onDeleteFromFolder} />
        ) : null}
      </div>
      <div
        style={{
          background: props.dish ? "white" : undefined,
          display: "grid",
          gridTemplateColumns: isOnMobile() ? "100px auto" : "220px auto",
          boxShadow: props.dish ? "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)" : undefined,
          height: "140px",
          borderRadius: "4px",
          border: !props.dish ? "2px dashed gray" : undefined,
        }}
        onClick={props.onClick}
      >
        {!props.dish ? (
          <div style={{ gridColumn: "span 2", textAlign: "center", alignSelf: "center", fontSize: 48 }}>?</div>
        ) : (
          <>
            <div style={{ display: "flex", flexDirection: "column", padding: "8px" }}>
              <Label text={props.dish.type} color={dishTypeToColor[props.dish.type]} padding="2px 8px" />
              <div
                style={{
                  whiteSpace: "nowrap",
                  margin: "8px 4px 0 4px",

                  color: "gray",
                }}
              >
                {props.dish.time} min
              </div>
            </div>
            <div style={{ height: "100%", width: "100%", objectFit: "cover" }}>
              <div
                style={{
                  backgroundImage: `url(${props.dish.imageUrl}), url(${PLACEHOLDER_URL})`,
                  width: "100%",
                  height: "100%",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
