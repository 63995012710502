import React from "react";
import { getYearWeekDayRelative, isOnMobile } from "../../lib/functions";
import { BLUE } from "../../lib/style_definitions";

interface WeekNumberBoxProps {
  weekNumber: number;
  selectedWeekNumber: number;
  onClick(): void;
}

export function WeekNumberBox(props: WeekNumberBoxProps) {
  const big = getYearWeekDayRelative(0).week === props.weekNumber;
  const selected = props.weekNumber === props.selectedWeekNumber;

  return (
    <div
      style={{
        padding: big ? "3px 4px 5px 3px" : "3px 4px 4px 4px",
        background: isOnMobile() ? "#f5f5f5" : "white",
        borderRadius: "4px",
        fontSize: big ? "22px" : "18px",
        margin: "0 8px",
        width: big ? "42px" : "36px",
        height: big ? "42px" : "36px",
        textAlign: "center",
        color: selected ? BLUE : "#9e9e9e",
        transition: "all 0.3s",
      }}
      onClick={props.onClick}
    >
      <div style={{ marginTop: big ? "1px" : "2px" }}>{props.weekNumber}</div>
      <div
        style={{
          height: "3px",
          background: selected ? BLUE : isOnMobile() ? "#f5f5f5" : undefined,
          transition: "all 0.3s",
          borderRadius: "12px",
          margin: big ? "-4px 6px 1px 6px" : "-4px 4px 0 4px",
        }}
      />
    </div>
  );
}
