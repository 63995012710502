import React, { useContext } from "react";
import { CalendarOutlined, SettingOutlined, ShoppingCartOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { NavigationBarItem } from "./navigationBarItem";
import { useHistory, useLocation } from "react-router-dom";
import { Badge } from "antd";
import { DataContext } from "../../lib/contexts";
import { isOnMobile } from "../../lib/functions";

interface NavigationBarProps {
  isInputting: boolean;
}

export function NavigationBar(props: NavigationBarProps) {
  const history = useHistory();
  const location = useLocation();

  const { shoppingList, refreshShoppingList, setCurrentAction, locked } = useContext(DataContext);

  const divider = <div style={{ height: "60%", width: "2px", background: "lightgray" }} />;

  return (
    <div
      style={{ display: props.isInputting && isOnMobile() ? "none" : undefined }}
      className={"navigation-bar" + (!isOnMobile() ? " navigation-bar__widescreen" : "")}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto 1fr auto 1fr auto 1fr",
          gridAutoRows: "1fr",
          alignItems: "center",
          height: "100%",
        }}
      >
        <NavigationBarItem
          onClick={() => {
            if (locked) {
              return;
            }

            if (location.pathname.includes("/shopping-list")) {
              refreshShoppingList();
            }
            history.push("/shopping-list");
            setCurrentAction(undefined);
          }}
          icon={
            <Badge count={(shoppingList?.items || []).length}>
              <ShoppingCartOutlined
                className={
                  "navigation-bar__icon" +
                  (location.pathname.includes("/shopping-list") ? " navigation-bar__icon--highlight" : "")
                }
              />
            </Badge>
          }
        />
        {divider}
        <NavigationBarItem
          onClick={() => {
            if (locked) {
              return;
            }

            history.push("/week-plan");
            setCurrentAction(undefined);
          }}
          icon={
            <CalendarOutlined
              className={
                "navigation-bar__icon" +
                (location.pathname.includes("/week-plan") ? " navigation-bar__icon--highlight" : "")
              }
            />
          }
        />
        {divider}
        <NavigationBarItem
          onClick={() => {
            if (locked) {
              return;
            }

            history.push("/catalogue");
            setCurrentAction(undefined);
          }}
          icon={
            <UnorderedListOutlined
              className={
                "navigation-bar__icon" +
                (location.pathname.includes("/catalogue") ? " navigation-bar__icon--highlight" : "")
              }
            />
          }
        />
        {divider}
        <NavigationBarItem
          onClick={() => {
            if (locked) {
              return;
            }

            history.push("/profile");
            setCurrentAction(undefined);
          }}
          icon={
            <SettingOutlined
              className={
                "navigation-bar__icon" +
                (location.pathname.includes("/profile") ? " navigation-bar__icon--highlight" : "")
              }
            />
          }
        />
      </div>
    </div>
  );
}
