import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import { FaAppleAlt } from "react-icons/fa";
import { getUserInfo, login } from "../lib/credentialsHandler";
import { DOMAIN } from "../lib/definitions";
import { forceRefresh } from "../lib/functions";
import { UserInfo } from "../lib/models";
import { BLUE, GRAY, GRAY_BACKGROUND } from "../lib/style_definitions";
import { MInput } from "./elements/mInputs";

interface LoginScreenProps {
  isWorking: boolean;
  setIsWorking(isWorking: boolean): void;
  onLogin(userInfo: UserInfo): void;
}

export function LoginScreen(props: LoginScreenProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setEmail(new URLSearchParams(window.location.search).get("email") || "");
  }, []);

  function doLogin() {
    props.setIsWorking(true);
    login(email, password)
      .then(() =>
        getUserInfo()
          .then(props.onLogin)
          .catch((e) => alert(e.message))
      )
      .catch((e) => {
        if (e.name === "NotAuthorizedException") {
          alert("Forkert email eller kodeord");
        } else {
          alert(e.message);
        }
      })
      .finally(() => props.setIsWorking(false));
  }

  return (
    <div style={{ height: "100vh", background: GRAY_BACKGROUND, position: "relative" }}>
      <div
        style={{
          color: "white",
          fontSize: "42px",
          justifyContent: "center",
          height: "160px",
          marginBottom: "72px",
          display: "flex",
          alignItems: "center",
          background: BLUE,
        }}
      >
        <FaAppleAlt style={{ marginRight: "12px", marginBottom: "8px" }} /> Madplanen
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Form>
          <Form.Item>
            <MInput
              prefix={<UserOutlined style={{ color: GRAY, margin: "0 4px 0 0" }} />}
              value={email}
              size="large"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email..."
              type="email"
            />
          </Form.Item>
          <Form.Item>
            <MInput
              type="password"
              prefix={<LockOutlined style={{ color: GRAY, margin: "0 4px 0 0" }} />}
              size="large"
              placeholder="Kodeord..."
              value={password}
              onPressEnter={doLogin}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              style={{ textAlign: "end", marginTop: "4px" }}
              onClick={() =>
                (window.location.href = `https://${DOMAIN}/forgot-password?redirect=${window.location.origin}/login`)
              }
            >
              Glemt kodeord?
            </div>
          </Form.Item>
        </Form>
        <Button
          size="large"
          onClick={doLogin}
          disabled={!email || !password || props.isWorking}
          loading={props.isWorking}
        >
          Log ind
        </Button>
        <div
          style={{ textAlign: "center", marginTop: "24px", fontSize: "16px" }}
          onClick={() => (window.location.href = `https://${DOMAIN}/sign-up?redirect=${window.location.origin}/login`)}
        >
          Opret konto
        </div>
      </div>
      <div
        style={{
          bottom: "36px",
          textAlign: "center",
          color: "gray",
          width: "100%",
          position: "absolute",
        }}
        onClick={forceRefresh}
      >
        {process.env.REACT_APP_VERSION}
      </div>
    </div>
  );
}
