import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { EditOutlined, LoadingOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { View } from "./view";
import { DataContext, GroupContext } from "../../lib/contexts";
import { ViewMessage } from "../general/viewMessage";
import { Button } from "antd";
import { BLUE } from "../../lib/style_definitions";
import { useHistory } from "react-router-dom";
import { DishCard } from "../dishes/dishCard";
import { doFetch, doFormalize, getStringFromYearWeekDay, isOnMobile } from "../../lib/functions";
import { Dish, WeekPlan } from "../../lib/models";
import { APIResources, Action, LF_LAST_FOLDER } from "../../lib/definitions";
import { DishPopup } from "../dishes/dishPopup";
import { MInput } from "../elements/mInputs";
import { Folder } from "../dishes/folder";
import { FcOpenedFolder } from "react-icons/fc";
import { MModal } from "../elements/mmodal";
import * as localForage from "localforage";
import { FaChevronLeft } from "react-icons/fa";

export function CatalogueView() {
  const [searchValue, setSearchValue] = useState("");
  const [selectedDish, setSelectedDish] = useState<Dish>();
  const [editingFolder, setEditFolder] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [isUpdatingFolder, setIsUpdatingFolder] = useState(false);
  const [isDeletingFolder, setIsDeletingFolder] = useState(false);
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [randomDish, setRandomDish] = useState<Dish>();

  const noScroll = useRef(false);

  const { group, hasFetchedGroup } = useContext(GroupContext);
  const {
    dishes,
    refreshWeekPlan,
    weekPlan,
    folders,
    isFetchingFolders,
    isFetchingDishes,
    refreshFolders,
    setCurrentAction,
    currentAction,
    setWeekPlan,
    setFolders,
    selectedFolder,
    setSelectedFolder,
  } = useContext(DataContext);

  const history = useHistory();

  useEffect(() => {
    setSelectedDish(
      dishes.find((dish) => dish._id === new URLSearchParams(window.location.search).get("selected-dish") || "")
    );
  }, [dishes]);

  useEffect(() => {
    if (currentAction?.action === "SELECT_FOLDER_FOR_DISH") {
      setSelectedFolder(undefined);
    }
  }, [currentAction, setSelectedFolder]);

  useEffect(() => {
    setFolderName(selectedFolder?.folderName || "");
    if (noScroll.current) {
      noScroll.current = false;
      return;
    }
    document.getElementById("folder-dish-container")?.scrollTo(0, 0);
  }, [selectedFolder]);

  const filteredDishes = useMemo(
    () =>
      dishes
        .filter((dish) => doFormalize(dish.name).includes(doFormalize(searchValue)))
        .filter((dish) => dish.groupShortId === group?.shortId)
        .filter(
          (dish) => !selectedFolder || selectedFolder.dishIds.includes(dish._id) || selectedFolder.folderName === "Alle"
        )
        .filter(
          (dish) => currentAction?.action !== "SELECT_DISH_FOR_FOLDER" || !currentAction.data.dishIds.includes(dish._id)
        ),
    [dishes, searchValue, group?.shortId, selectedFolder, currentAction]
  );

  function updateRandomDish() {
    const groupDishes = dishes.filter((dish) => dish.groupShortId === group?.shortId);

    setRandomDish(groupDishes[Math.floor(Math.random() * groupDishes.length)]);
  }

  return (
    <View>
      {!group ? (
        <ViewMessage
          message="Du er ikke en del af nogen gruppe endnu."
          otherMessage="Gå i indstillinger for at lave eller deltage i en gruppe..."
          loading={!hasFetchedGroup}
        />
      ) : (
        <>
          <div
            style={{
              background: isOnMobile() ? "white" : undefined,
              padding: "12px 20px",
              width: "100%",
              boxShadow: isOnMobile() ? "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)" : undefined,
              display: "flex",
              alignItems: "center",
              position: "sticky",
              zIndex: 100,
            }}
          >
            <SearchOutlined style={{ marginRight: "8px", fontSize: "20px" }} />
            <MInput
              size="large"
              placeholder={selectedFolder ? "Søg opskrifter..." : "Søg mapper..."}
              allowClear
              className={"new-item-input" + (isOnMobile() ? "" : " new-item-input__widescreen")}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {!currentAction && (
              <div
                style={{
                  fontSize: "16px",
                  color: BLUE,
                  fontWeight: "bold",
                  marginLeft: "24px",
                  width: "40px",
                }}
                onClick={() => history.push("/upsert-dish")}
              >
                {isFetchingFolders || isFetchingDishes ? (
                  <LoadingOutlined style={{ color: BLUE, fontSize: "24px", marginTop: "4px" }} />
                ) : (
                  "NY"
                )}
              </div>
            )}
          </div>
          {filteredDishes.length === 0 && selectedFolder?.folderName === "Alle" && (
            <ViewMessage message="Tryk på NY for at oprette en ret..." />
          )}
          <div
            id="folder-dish-container"
            style={{
              overflow: "auto",
              height: "calc(100% - 62px)",
              width: "100%",
              padding: "0 24px",
            }}
          >
            {selectedFolder ? (
              <>
                <div
                  style={{
                    display: "grid",
                    alignItems: "center",
                    margin: "18px 0",
                    height: "40px",
                    gridTemplateColumns: "min-content min-content auto min-content",
                  }}
                >
                  <FaChevronLeft
                    style={{ marginRight: 8, fontSize: 20, color: "#636363" }}
                    onClick={() => {
                      setSelectedFolder(undefined);
                      localForage.setItem(LF_LAST_FOLDER, "");
                    }}
                  />
                  <FcOpenedFolder
                    style={{ marginRight: "8px", fontSize: "28px" }}
                    onClick={() => {
                      setSelectedFolder(undefined);
                      localForage.setItem(LF_LAST_FOLDER, "");
                    }}
                  />
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      marginRight: "8px",
                      color: "#636363",
                    }}
                    onClick={() => {
                      setSelectedFolder(undefined);
                      localForage.setItem(LF_LAST_FOLDER, "");
                    }}
                  >
                    {selectedFolder.folderName}
                  </div>
                  {selectedFolder.folderName !== "Alle" && !currentAction ? (
                    <Button size="large" onClick={() => setEditFolder(true)} icon={<EditOutlined />}>
                      Rediger
                    </Button>
                  ) : (
                    <div />
                  )}
                </div>
                {filteredDishes.length === 0 && (
                  <div style={{ textAlign: "center", padding: "24px 0" }}>
                    {searchValue
                      ? `Ingen retter i mappen '${folderName}' matcher søgningen '${searchValue}'`
                      : "Denne mappe indeholder endnu ikke nogle retter"}
                  </div>
                )}
                <div style={{ display: "grid", rowGap: "32px" }}>
                  {filteredDishes.map((dish) => (
                    <DishCard
                      key={dish._id}
                      dish={dish}
                      onDeleteFromFolder={
                        selectedFolder.folderName === "Alle"
                          ? undefined
                          : () => {
                              // instant update
                              noScroll.current = true;
                              setSelectedFolder({
                                ...selectedFolder,
                                dishIds: selectedFolder.dishIds.filter((did) => did !== dish._id),
                              });

                              doFetch(
                                "DELETE",
                                `${APIResources.FolderContent}/${selectedFolder._id}`,
                                refreshFolders,
                                () => alert("Sletning af ret fra mappe: Der skete en fejl"),
                                undefined,
                                { body: { dishIds: [dish._id] } }
                              );
                            }
                      }
                      onClick={() => {
                        if (currentAction?.action === "SELECT_DISH_FOR_DAY") {
                          setCurrentAction(undefined);
                          history.push(`/week-plan?week=${currentAction.data.week}`);

                          const ywdString = getStringFromYearWeekDay(currentAction.data);
                          const newDishes = (weekPlan?.weekPlans[ywdString] || []).concat([dish._id]);

                          // instant update
                          const newWeekPlan: WeekPlan = JSON.parse(JSON.stringify(weekPlan));
                          newWeekPlan.weekPlans[ywdString] = newDishes;
                          setWeekPlan(newWeekPlan);

                          doFetch(
                            "PUT",
                            `${APIResources.WeekPlans}/${group.shortId}`,
                            refreshWeekPlan,

                            () => alert("Opdatering af madplan: Der skete en fejl"),
                            undefined,
                            {
                              body: {
                                yearWeekDay: ywdString,
                                dishes: newDishes,
                              },
                            }
                          );
                        } else if (currentAction?.action === "SELECT_DISH_FOR_FOLDER") {
                          setSearchValue("");
                          setIsUpdatingFolder(true);

                          // instant update
                          setFolders(
                            folders.map((f) =>
                              f._id === currentAction.data._id ? { ...f, dishIds: f.dishIds.concat(dish._id) } : f
                            )
                          );
                          setCurrentAction({
                            ...currentAction,
                            data: { ...currentAction.data, dishIds: currentAction.data.dishIds.concat(dish._id) },
                          });

                          doFetch(
                            "POST",
                            `${APIResources.FolderContent}/${currentAction.data._id}`,
                            () => {},
                            () => alert("Opdatering af mappe: Der skete en fejl"),
                            () => setIsUpdatingFolder(false),
                            { body: { dishIds: [dish._id] } }
                          );

                          localForage.setItem(LF_LAST_FOLDER, currentAction.data._id);
                        } else {
                          setSelectedDish(dish);
                        }
                      }}
                    />
                  ))}
                  {selectedFolder.folderName !== "Alle" && !currentAction && (
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "36px" }}>
                      <Button
                        size="large"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          setCurrentAction({ action: Action.SelectDishForFolder, data: selectedFolder });
                          setSelectedFolder(folders.find((f) => f.folderName === "Alle"));
                        }}
                      >
                        Tilføj retter til mappe
                      </Button>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                {!currentAction && (
                  <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "16px" }}>
                    <Button icon={<PlusOutlined />} size="large" onClick={() => setCreatingFolder(true)}>
                      Ny mappe
                    </Button>
                  </div>
                )}
                <div
                  style={{
                    display: "grid",
                    gap: "32px",
                    gridTemplateColumns: isOnMobile() ? "1fr 1fr" : "1fr 1fr 1fr 1fr",
                    marginTop: "36px",
                  }}
                >
                  {folders
                    .filter(
                      (f) =>
                        currentAction?.action !== "SELECT_FOLDER_FOR_DISH" ||
                        (f.folderName !== "Alle" && !f.dishIds.includes(currentAction.data._id))
                    )
                    .filter(
                      (f) =>
                        !searchValue || (searchValue && doFormalize(f.folderName).includes(doFormalize(searchValue)))
                    )
                    .map((folder) => (
                      <Folder
                        key={folder._id}
                        folder={folder}
                        onClick={async () => {
                          if (currentAction?.action === "SELECT_FOLDER_FOR_DISH") {
                            // instant-update
                            setFolders(
                              folders.map((f) =>
                                f._id === folder._id ? { ...f, dishIds: f.dishIds.concat(currentAction.data._id) } : f
                              )
                            );
                            setSelectedDish(currentAction.data);
                            setSelectedFolder(folder);
                            await localForage.setItem(LF_LAST_FOLDER, folder._id);
                            setCurrentAction(undefined);

                            doFetch(
                              "POST",
                              `${APIResources.FolderContent}/${folder._id}`,
                              () => {},
                              () => alert("Tilføjelse af ret til mappe: Der skete en fejl"),
                              undefined,
                              { body: { dishIds: [currentAction.data._id] } }
                            );
                          } else {
                            setSelectedFolder(folder);
                            setSearchValue("");
                            localForage.setItem(LF_LAST_FOLDER, folder._id);
                          }
                        }}
                      />
                    ))}
                </div>
                {currentAction?.action !== Action.SelectFolderForDish && (
                  <div style={{ marginTop: 48 }}>
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: 8 }}>
                      <Button size="large" onClick={() => updateRandomDish()}>
                        Prøv lykken
                      </Button>
                    </div>

                    <DishCard
                      dish={randomDish}
                      onClick={() => {
                        if (!randomDish) return;

                        if (currentAction?.action === Action.SelectDishForDay) {
                          setCurrentAction(undefined);
                          history.push(`/week-plan?week=${currentAction.data.week}`);

                          const ywdString = getStringFromYearWeekDay(currentAction.data);
                          const newDishes = (weekPlan?.weekPlans[ywdString] || []).concat([randomDish._id]);

                          // instant update
                          const newWeekPlan: WeekPlan = JSON.parse(JSON.stringify(weekPlan));
                          newWeekPlan.weekPlans[ywdString] = newDishes;
                          setWeekPlan(newWeekPlan);

                          doFetch(
                            "PUT",
                            `${APIResources.WeekPlans}/${group.shortId}`,
                            refreshWeekPlan,

                            () => alert("Opdatering af madplan: Der skete en fejl"),
                            undefined,
                            {
                              body: {
                                yearWeekDay: ywdString,
                                dishes: newDishes,
                              },
                            }
                          );
                        } else {
                          setSelectedDish(randomDish);
                        }
                      }}
                    />
                  </div>
                )}
              </>
            )}
            <div style={{ marginBottom: "260px" }} />
          </div>
        </>
      )}
      {selectedDish && (
        <DishPopup
          dish={selectedDish}
          visible={!!selectedDish}
          onClose={() => setSelectedDish(undefined)}
          onAddToWeekPlan={() => {
            setCurrentAction({ action: Action.SelectDayForDish, data: selectedDish });
            history.push("/week-plan");
          }}
          folders={folders}
        />
      )}
      <MModal
        visible={creatingFolder}
        onClose={() => setCreatingFolder(false)}
        title="Ny mappe"
        content={
          <div>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "24px" }}>
              <FcOpenedFolder style={{ fontSize: "120px" }} />
            </div>
            <MInput
              size="large"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="Mappenavn..."
            />
          </div>
        }
        footer={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              size="large"
              onClick={() => {
                setCreatingFolder(false);
                setNewFolderName("");
              }}
            >
              Luk
            </Button>
            <Button
              size="large"
              type="primary"
              loading={isCreatingFolder}
              onClick={() => {
                setIsCreatingFolder(true);
                doFetch(
                  "POST",
                  `${APIResources.Folders}/${group?.shortId}`,
                  () => {
                    setCreatingFolder(false);
                    refreshFolders();
                    setNewFolderName("");
                  },
                  () => alert("Oprettelse af ny mappe: Der skete en fejl"),
                  () => setIsCreatingFolder(false),
                  { body: { folderName: newFolderName } }
                );
              }}
            >
              Opret
            </Button>
          </div>
        }
      />
      <MModal
        visible={editingFolder}
        onClose={() => setEditFolder(false)}
        title="Rediger mappe"
        content={
          <div>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "24px" }}>
              <FcOpenedFolder style={{ fontSize: "120px" }} />
            </div>
            <MInput
              placeholder="Mappenavn..."
              value={folderName}
              size="large"
              onChange={(e) => setFolderName(e.target.value)}
            />
          </div>
        }
        footer={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button size="large" onClick={() => setEditFolder(false)}>
              Luk
            </Button>
            <div style={{ display: "flex", columnGap: "8px" }}>
              <Button
                size="large"
                danger
                type="primary"
                loading={isDeletingFolder}
                onClick={() => {
                  if (window.confirm(`Er du sikker på at du vil slette mappen '${selectedFolder?.folderName}''`)) {
                    setIsDeletingFolder(true);
                    doFetch(
                      "DELETE",
                      `${APIResources.Folders}/${selectedFolder?._id}`,
                      () => {
                        refreshFolders();
                        setEditFolder(false);
                        setSelectedFolder(undefined);
                      },
                      () => alert("Sletning af mappe: Der skete en fejl"),
                      () => setIsDeletingFolder(false)
                    );
                  }
                }}
              >
                Slet
              </Button>
              <Button
                size="large"
                type="primary"
                loading={isUpdatingFolder}
                onClick={() => {
                  setIsUpdatingFolder(true);
                  doFetch(
                    "PUT",
                    `${APIResources.Folders}/${selectedFolder?._id}`,
                    () => {
                      refreshFolders();
                      setEditFolder(false);
                    },
                    () => alert("Opdatering af mappe: Der skete en fejl"),
                    () => setIsUpdatingFolder(false),
                    { body: { folderName: folderName } }
                  );
                }}
              >
                Opdater
              </Button>
            </div>
          </div>
        }
      />
    </View>
  );
}
