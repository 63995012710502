import React from "react";

interface NavigationBarItemProps {
  icon: any;
  onClick(): void;
}

export function NavigationBarItem(props: NavigationBarItemProps) {
  return (
    <div
      style={{
        outline: "none",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={props.onClick}
    >
      {props.icon}
    </div>
  );
}
