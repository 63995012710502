import { ShoppingCartOutlined } from "@ant-design/icons";
import { Button, Checkbox } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../lib/contexts";
import { APIResources, categoryToIcon, UpdateShoppingListAction } from "../../lib/definitions";
import { doFetch } from "../../lib/functions";
import { ItemIdAndAmount } from "../../lib/models";
import { MModal } from "../elements/mmodal";

interface AddToShoppingListPopupProps {
  visible: boolean;
  onClose(): void;
  dishIds: string[];
}

export function AddToShoppingListPopup(props: AddToShoppingListPopupProps) {
  const [allIngredients, setAllIngredients] = useState<ItemIdAndAmount[]>([]);
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [isTransferring, setIsTransferring] = useState(false);

  const { dishes, shoppingList, refreshShoppingList, itemsMap } = useContext(DataContext);

  useEffect(() => {
    const ingredients: { [name in string]: ItemIdAndAmount } = {};

    props.dishIds.forEach((dishId) =>
      (dishes.find((dish) => dish._id === dishId)?.ingredients || []).forEach((ing) => {
        if (!ingredients[ing.id]) {
          ingredients[ing.id] = { _id: ing.id, amount: ing.amount };
        } else {
          ingredients[ing.id].amount += ing.amount;
        }
      })
    );

    setAllIngredients(Object.values(ingredients));
    setSelectedItemIds(Object.keys(ingredients));
  }, [props.dishIds, dishes]);

  function onItemClick(item: ItemIdAndAmount) {
    if (selectedItemIds.includes(item._id)) {
      setSelectedItemIds((is) => is.filter((id) => id !== item._id));
    } else {
      setSelectedItemIds((is) => is.concat(item._id));
    }
  }

  return (
    <MModal
      visible={props.visible}
      onClose={props.onClose}
      title="Overfør til indkøbsliste"
      content={
        <div>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "24px" }}>
            <ShoppingCartOutlined style={{ fontSize: "120px" }} />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "min-content min-content auto min-content",
              columnGap: "6px",
              rowGap: "4px",
              alignItems: "center",
            }}
          >
            {allIngredients.map((i) => {
              const item = itemsMap[i._id];
              return (
                <React.Fragment key={i._id}>
                  <div className="zoom" style={{ marginRight: "8px" }}>
                    <Checkbox checked={selectedItemIds.includes(i._id)} onClick={() => onItemClick(i)} />
                  </div>
                  <div style={{ whiteSpace: "nowrap", marginTop: -3 }}>{i.amount > 1 ? `${i.amount} x` : ""}</div>
                  <div
                    onClick={() => onItemClick(i)}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      marginRight: "8px",
                      marginTop: -3,
                    }}
                  >
                    {item.name}
                  </div>
                  <div style={{ fontSize: 20 }}>{categoryToIcon[item.category]}</div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      }
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button size="large" style={{ marginRight: "8px" }} onClick={props.onClose}>
            Luk
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={() => {
              setIsTransferring(true);
              doFetch(
                "PUT",
                APIResources.ShoppingLists + `/${shoppingList?.groupShortId}`,
                () => {
                  props.onClose();
                  refreshShoppingList();
                },
                () => alert("Opdatering af indkøbliste: Der skete en fejl"),
                () => setIsTransferring(false),
                {
                  body: {
                    items: allIngredients
                      .filter((ing) => selectedItemIds.includes(ing._id))
                      .map((ing) => ({ _id: ing._id, amount: ing.amount })),
                    action: UpdateShoppingListAction.Add,
                  },
                }
              );
            }}
            disabled={selectedItemIds.length === 0}
            loading={isTransferring}
          >
            Overfør
          </Button>
        </div>
      }
    />
  );
}
