import React from "react";
import { FaAppleAlt } from "react-icons/fa";
import { BLUE } from "../../lib/style_definitions";

export function WelcomeScreen() {
  return (
    <div style={{ height: "100vh", background: BLUE }}>
      <FaAppleAlt
        style={{
          fontSize: "128px",
          position: "absolute",
          top: "35%",
          color: "white",
          width: "100%",
        }}
      />
    </div>
  );
}
