import { Button, Select } from "antd";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { DataContext } from "../../lib/contexts";
import { APIResources, Category, categoryToIcon } from "../../lib/definitions";
import { capitalizeFirstLetter, doFetch } from "../../lib/functions";
import { BLACK } from "../../lib/style_definitions";
import { EcoLabel } from "../elements/ecoLabel";
import { MInput } from "../elements/mInputs";

interface CreateItemContentProps {
  initialItemName: string;
  onFinish(itemId: string, isOther?: number): void;
  onClose(): void;
}

export function CreateItemContent(props: CreateItemContentProps) {
  const [itemName, setItemName] = useState(capitalizeFirstLetter(props.initialItemName));
  const [selectedCategory, setSelectedCategory] = useState<{ label: React.ReactNode; value: Category }>();
  const [isEcologic, setIsEcologic] = useState(false);

  const { refreshItems, setItems, items } = useContext(DataContext);

  return (
    <div>
      <MInput
        value={itemName}
        onChange={(e) => setItemName(e.target.value)}
        size="large"
        placeholder="Varens navn..."
      />
      <Select
        defaultOpen
        style={{ marginTop: "16px", width: "100%" }}
        options={Object.values(Category)
          .filter((cat) => ![Category.Bought].includes(cat))
          .map((cat) => ({
            label: (
              <div style={{ fontSize: "20px", display: "flex" }}>
                <div style={{ width: "34px", display: "flex", alignItems: "center" }}>{categoryToIcon[cat]}</div>
                <div style={{ color: BLACK, fontSize: "16px", margin: "2px" }}>{cat}</div>
              </div>
            ),
            value: cat,
          }))}
        value={selectedCategory}
        labelInValue
        onChange={(val) => setSelectedCategory(val)}
        placeholder="Vælg kategori..."
        size="large"
      />
      <div style={{ marginTop: "16px", display: "flex", justifyContent: "center" }}>
        <EcoLabel size={3} onClick={() => setIsEcologic(!isEcologic)} color={isEcologic ? undefined : "lightgray"} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "24px",
        }}
      >
        <Button size="large" style={{ marginRight: "8px" }} onClick={props.onClose}>
          Luk
        </Button>
        <Button
          size="large"
          type="primary"
          disabled={!selectedCategory || !itemName}
          onClick={() => {
            const item = {
              name: capitalizeFirstLetter(itemName),
              category: selectedCategory!.value,
              isEcologic: isEcologic,
            };

            if (selectedCategory?.value !== Category.Other) {
              doFetch(
                "POST",
                APIResources.Items,
                (id) => {
                  refreshItems(true);
                  setItems(items.concat({ _id: id, ...item }));
                  props.onFinish(id);
                },
                (statusCode) =>
                  statusCode === 400
                    ? alert("Oprettelse af vare: Denne vare findes allerede")
                    : alert("Oprettelse af vare: Der skete en fejl"),
                undefined,
                { body: item }
              );
            } else {
              props.onFinish(item.name, isEcologic ? 2 : 1);
            }
          }}
        >
          {selectedCategory?.value === "Andet" ? "Tilføj" : "Opret"}
        </Button>
      </div>
    </div>
  );
}
