import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { CatalogueView } from "./views/catalogView";
import { WeekView } from "./views/weekView";
import { ShoppingView } from "./views/shoppingView";
import { NavigationBar } from "./general/navigationBar";
import { AllUsersContext, DataContext, GroupContext, UserInfoContext } from "../lib/contexts";
import { SettingsView } from "./views/settingsView";
import { Action, APIResources, DOMAIN, LF_LAST_PATH } from "../lib/definitions";
import { Group, Item, Dish, ShoppingList, User, UserInfo, WeekPlan, FolderI } from "../lib/models";
import { Route, Switch, Redirect } from "react-router-dom";
import { checkVersionRefresh, doFetch } from "../lib/functions";
import { UpsertDish } from "./views/upsertDish";
import * as localForage from "localforage";
import { useLocation } from "react-router-dom";
import { fetchStatistics } from "../lib/statisticsHandler";
import { CurrentActionBar } from "./general/currentActionBar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Avatar } from "./general/Avatar";

export let ws: WebSocket;

interface MainProps {
  userInfo: UserInfo;
  onLogout(): void;
}

export function Main(props: MainProps) {
  const [group, setGroup] = useState<Group>();
  const [items, setItems] = useState<Item[]>([]);
  const [isFetchingItems, setIsFetchingItems] = useState(false);
  const [hasFetchedGroup, setHasFetchedGroup] = useState(false);
  const [weekPlan, setWeekPlan] = useState<WeekPlan>();
  const [isFetchingWeekPlan, setIsFetchingWeekPlan] = useState(false);
  const [dishes, setDishes] = useState<Dish[]>([]);
  const [isFetchingDishes, setIsFetchingDishes] = useState(false);
  const [shoppingList, setShoppingList] = useState<ShoppingList>();
  const [isFetchingShoppingList, setIsFetchingShoppingList] = useState(true);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [isInputting, setIsInputting] = useState(false);
  const [folders, setFolders] = useState<FolderI[]>([]);
  const [isFetchingFolders, setIsFetchingFolders] = useState(false);
  const [currentAction, setCurrentAction] = useState<{ action: Action; data: any }>();
  const [selectedFolder, setSelectedFolder] = useState<FolderI>();
  const [locked, setLocked] = useState(false);

  const location = useLocation();

  const fetchMyGroup = useCallback(async () => {
    await doFetch("GET", APIResources.MyGroup, setGroup, (statusCode) => {
      if (statusCode === 404) {
        setGroup(undefined);
      } else {
        alert("Hentning af gruppe: Der skete en fejl");
      }
    });
    setHasFetchedGroup(true);
  }, []);

  useEffect(() => {
    refreshDishes();
    refreshItems();
    refreshUsers();
  }, []);

  useEffect(() => {
    fetchMyGroup();
  }, [fetchMyGroup]);

  useEffect(() => {
    localForage.setItem(LF_LAST_PATH, location.pathname);
  }, [location]);

  const refreshShoppingList = useCallback(() => {
    if (!group) {
      return;
    }

    setIsFetchingShoppingList(true);

    doFetch(
      "GET",
      APIResources.ShoppingLists + `/${group?.shortId}`,
      setShoppingList,
      (_, error) => {
        console.log(error);
        alert(`Hentning af indkøbliste: Der skete en fejl: ${error}`);
      },
      () => setIsFetchingShoppingList(false)
    );
  }, [group]);

  const connect = useCallback(
    (force?: boolean) => {
      if (!force && ws) {
        return;
      }

      ws = new WebSocket(`wss://ckal.dk?email=${props.userInfo.email}&app=Madplanen`);

      ws.onclose = () => {
        //@ts-ignore
        ws = undefined;
        setTimeout(connect, 1000);
      };
    },
    [props.userInfo]
  );

  const toastId = useRef("");

  function notifyShoppingList(message: React.ReactNode) {
    toast.dismiss(toastId.current);
    setTimeout(() => {
      toastId.current = toast(message, {
        toastId: `${Math.random()}`,
        position: "bottom-center",
        icon: null,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
        style: { margin: "0 24px", borderRadius: 16 },
        closeButton: false,
      }) as string;
    }, 400);
  }

  const itemsMap = useMemo(() => {
    const m: { [id in string]: Item } = {};
    items.forEach((item) => (m[item._id] = item));
    return m;
  }, [items]);

  useEffect(() => {
    if (ws) {
      ws.onmessage = (me: MessageEvent) => {
        const data = JSON.parse(me.data);

        if (data.action !== "Add" && data.action !== "Buy") {
          return;
        }

        const user = allUsers.find((u) => u.email === data.email);
        const itemNames: string[] = Array.isArray(data.items)
          ? data.items.map((item: any) => itemsMap[item._id]?.name).filter((name: string) => !!name)
          : [];

        if (itemNames.length === 0) {
          return;
        }

        notifyShoppingList(
          <div style={{ display: "flex", alignItems: "center", columnGap: 8 }}>
            <Avatar
              email={user?.email || ""}
              firstname={user?.firstname || ""}
              lastname={user?.lastname || ""}
              size={32}
            />
            <div>
              {user?.firstname} {data.action === "Add" ? "tilføjede" : "fjernede"} {itemNames.join(", ")}
            </div>
          </div>
        );
        refreshShoppingList();
      };
    }
  }, [refreshShoppingList, itemsMap, allUsers]);

  useEffect(() => connect(), [connect]);

  const refreshFolders = useCallback(() => {
    setIsFetchingFolders(true);
    doFetch(
      "GET",
      APIResources.Folders + "/me",
      (res) => setFolders([{ _id: "__something__", folderName: "Alle", dishIds: [] }].concat(...res)),
      (statusCode) => statusCode !== 404 && alert("Hentning af mapper: Der skete en fejl"),
      () => setIsFetchingFolders(false)
    );
  }, []);

  const refreshWeekPlan = useCallback(() => {
    if (!group) {
      return;
    }

    setIsFetchingWeekPlan(true);
    doFetch(
      "GET",
      `${APIResources.WeekPlans}/${group?.shortId}`,
      setWeekPlan,
      (_, error) => {
        alert(`Hentning af madplan: Der skete en fejl: ${error}`);
      },
      () => setIsFetchingWeekPlan(false)
    );
  }, [setWeekPlan, group]);

  const refresh = useCallback(() => {
    refreshShoppingList();
    refreshWeekPlan();
    refreshFolders();
  }, [refreshShoppingList, refreshWeekPlan, refreshFolders]);

  useEffect(() => {
    refresh();
    checkVersionRefresh();

    function refreshOnVisibilityChange() {
      if (document.visibilityState === "visible") {
        refresh();
        checkVersionRefresh();
      }
    }

    document.addEventListener("visibilitychange", refreshOnVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", refreshOnVisibilityChange);
    };
  }, [refresh]);

  function refreshDishes() {
    setIsFetchingDishes(true);
    doFetch(
      "GET",
      APIResources.Dishes,
      (res: Dish[]) => setDishes(res.sort((a, b) => a.name.localeCompare(b.name))),
      (statusCode) => statusCode !== 404 && alert("Hentning af opskrifter: Der skete en fejl"),
      () => setIsFetchingDishes(false)
    );
  }

  function refreshItems(noLoading?: boolean) {
    if (!noLoading) {
      setIsFetchingItems(true);
    }
    doFetch(
      "GET",
      APIResources.Items,
      (items: Item[]) => {
        fetchStatistics().then((statistics) => {
          setItems(
            items.map((i) => ({
              ...i,
              popularity: statistics[i._id] ? statistics[i._id].popularity : 0,
            }))
          );
        });
      },
      () => alert("Hentning af varer: Der skete en fejl"),
      () => setIsFetchingItems(false)
    );
  }

  function refreshUsers() {
    doFetch(
      "GET",
      `https://api.${DOMAIN}/users`,
      setAllUsers,
      () => alert("Hentning af brugere: Der skete en fejl"),
      undefined,
      { customUrl: true }
    );
  }

  return (
    <GroupContext.Provider value={{ group, fetchMyGroup, hasFetchedGroup }}>
      <DataContext.Provider
        value={{
          shoppingList,
          isFetchingShoppingList,
          setShoppingList,
          dishes: dishes,
          refreshDishes,
          isFetchingDishes,
          weekPlan,
          setWeekPlan,
          refreshWeekPlan,
          isFetchingWeekPlan,
          items,
          setItems,
          itemsMap,
          refreshItems,
          isFetchingItems,
          refreshShoppingList,
          setIsInputting,
          folders,
          setFolders,
          isFetchingFolders,
          refreshFolders,
          currentAction,
          setCurrentAction,
          selectedFolder,
          setSelectedFolder,
          locked,
          setLocked,
        }}
      >
        <UserInfoContext.Provider value={props.userInfo}>
          <AllUsersContext.Provider value={allUsers}>
            <Switch>
              <Route path="/shopping-list" component={ShoppingView} />
              <Route path="/week-plan" component={WeekView} />
              <Route path="/catalogue" component={CatalogueView} />
              <Route path="/profile" render={() => <SettingsView onLogout={props.onLogout} />} />
              <Route path="/upsert-dish/:id?" component={UpsertDish} />
              <Route path="/" render={() => <Redirect to="/shopping-list" />} />
            </Switch>
            {currentAction && (
              <CurrentActionBar currentAction={currentAction} onCancel={() => setCurrentAction(undefined)} />
            )}
            <NavigationBar isInputting={isInputting} />
          </AllUsersContext.Provider>
        </UserInfoContext.Provider>
      </DataContext.Provider>
    </GroupContext.Provider>
  );
}
