import { Button, Progress } from "antd";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { DataContext } from "../../lib/contexts";
import { categoryToIcon } from "../../lib/definitions";
import { Statistic } from "../../lib/models";
import { clearStatistic, clearStatistics, getStatistics } from "../../lib/statisticsHandler";
import { EcoLabel } from "../elements/ecoLabel";
import { SettingsContentContainer } from "./settingsContentContainer";

export function StatisticsContent() {
  const [statistics, setStatistics] = useState<Statistic[]>([]);

  const { refreshItems, itemsMap } = useContext(DataContext);

  useEffect(() => {
    getStatistics().then(setStatistics);
  }, []);

  return (
    <SettingsContentContainer>
      {Object.keys(itemsMap).length === 0 || statistics.length === 0 ? (
        <div style={{ textAlign: "center", fontSize: "18px" }}>Ingen statistik...</div>
      ) : (
        <div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "min-content auto min-content min-content",
              rowGap: "8px",
              columnGap: "16px",
              borderRadius: "4px",
              border: "1px solid lightgray",
              padding: "12px 18px",
              height: "256px",
              overflow: "auto",
              gridAutoRows: "min-content",
              alignItems: "center",
              fontSize: "18px",
            }}
          >
            {statistics.map((it) => {
              const item = { ...itemsMap[it._id], ...it };
              return (
                <React.Fragment key={item._id}>
                  <div style={{ paddingTop: "4px" }}>{categoryToIcon[item.category]}</div>
                  <div
                    style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                    onClick={() => {
                      if (window.confirm(`Vil du slette ${item.name} fra statistikken?`)) {
                        clearStatistic(item.name).then(() => getStatistics().then(setStatistics));
                      }
                    }}
                  >
                    {item.name.slice(0, 12) === "__økologisk " ? item.name.slice(12) : item.name}
                  </div>
                  {item.name.slice(0, 12) === "__økologisk " ? <EcoLabel size={1} /> : <div />}
                  <Progress percent={item.popularity * 100} steps={5} size="small" showInfo={false} />
                </React.Fragment>
              );
            })}
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "16px" }}>
            <Button
              size="large"
              danger
              onClick={() => {
                if (window.confirm("Er du sikker på du vil slette din statistik?")) {
                  clearStatistics()
                    .then(() => getStatistics().then(setStatistics))
                    .then(() => refreshItems());
                }
              }}
            >
              Slet statistik
            </Button>
          </div>
        </div>
      )}
    </SettingsContentContainer>
  );
}
