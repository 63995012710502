import { createContext } from "react";
import { Action } from "./definitions";
import { Group, Item, Dish, ShoppingList, User, UserInfo, WeekPlan, FolderI } from "./models";

export const GroupContext = createContext<{
  group: Group | undefined;
  hasFetchedGroup: boolean;
  fetchMyGroup(): Promise<void>;
}>({ group: undefined, hasFetchedGroup: false, fetchMyGroup: async () => {} });

export const DataContext = createContext<{
  shoppingList: ShoppingList | undefined;
  isFetchingShoppingList: boolean;
  setShoppingList(shoppingList: ShoppingList): void;
  dishes: Dish[];
  isFetchingDishes: boolean;
  refreshDishes(): void;
  weekPlan: WeekPlan | undefined;
  setWeekPlan(weekPlan: WeekPlan): void;
  refreshWeekPlan(): void;
  isFetchingWeekPlan: boolean;
  items: Item[];
  setItems(items: Item[]): void;
  itemsMap: { [id in string]: Item };
  refreshItems(noLoading?: boolean): void;
  isFetchingItems: boolean;
  refreshShoppingList(): void;
  setIsInputting(isInputting: boolean): void;
  folders: FolderI[];
  setFolders(folders: FolderI[]): void;
  isFetchingFolders: boolean;
  refreshFolders(): void;
  currentAction: { action: Action; data: any } | undefined;
  setCurrentAction(currentAction: { action: Action; data: any } | undefined): void;
  selectedFolder: FolderI | undefined;
  setSelectedFolder: (folder: FolderI | undefined) => void;
  locked: boolean;
  setLocked(locked: boolean): void;
}>({
  shoppingList: undefined,
  isFetchingShoppingList: false,
  setShoppingList: () => {},
  dishes: [],
  isFetchingDishes: false,
  refreshDishes: () => {},
  weekPlan: undefined,
  setWeekPlan: () => {},
  refreshWeekPlan: () => {},
  isFetchingWeekPlan: false,
  items: [],
  setItems: () => {},
  itemsMap: {},
  refreshItems: () => {},
  isFetchingItems: false,
  refreshShoppingList: () => {},
  setIsInputting: () => {},
  folders: [],
  setFolders: () => {},
  isFetchingFolders: false,
  refreshFolders: () => {},
  currentAction: undefined,
  setCurrentAction: () => {},
  selectedFolder: undefined,
  setSelectedFolder: () => {},
  locked: false,
  setLocked: () => {},
});

export const UserInfoContext = createContext<UserInfo>({ email: "", firstname: "", lastname: "", nickname: "" });

export const AllUsersContext = createContext<User[]>([]);
