import { Spin } from "antd";
import React, { HTMLProps } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { isOnMobile } from "../../lib/functions";
import { BLUE, GRAY_BACKGROUND } from "../../lib/style_definitions";

interface ViewProps extends HTMLProps<"div"> {
  isLoading?: boolean;
  scrollable?: boolean;
  header?: React.ReactNode;
  goBack?: boolean;
  scrollableContainerId?: string;
}

export function View(props: ViewProps) {
  const history = useHistory();

  return (
    <div
      id={props.scrollableContainerId}
      style={{
        height: isOnMobile() ? "100%" : "calc(100%-54px)",
        marginTop: isOnMobile() ? undefined : "54px",
        overflowY: props.scrollable ? "auto" : "hidden",
        overflowX: "hidden",
        background: GRAY_BACKGROUND,
      }}
    >
      {props.isLoading ? (
        <div
          style={{
            marginTop: "70%",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          {props.header && (
            <div
              style={{
                padding: isOnMobile() ? "24px" : 0,
                fontSize: "26px",
                fontWeight: "bold",
                background: BLUE,
                color: "white",
                marginBottom: "24px",
                width: "100%",
                textAlign: "center",
                position: "relative",
                visibility: isOnMobile() ? undefined : "hidden",
                height: isOnMobile() ? undefined : 0,
              }}
            >
              {props.goBack && isOnMobile() && (
                <FaChevronLeft
                  style={{ position: "absolute", left: "24px", top: "50%", transform: "translate(0,-50%)" }}
                  onClick={history.goBack}
                />
              )}
              {typeof props.header === "string" ? props.header.toString().toUpperCase() : props.header}
            </div>
          )}
          {isOnMobile() ? (
            props.children
          ) : (
            <div style={{ display: "flex", justifyContent: "center", width: "100vw" }}>
              <div style={{ width: "800px" }}>{props.children}</div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
