import { DownOutlined, UpOutlined } from "@ant-design/icons";
import React, { CSSProperties } from "react";
import { Category, categoryToColor, categoryToIcon } from "../../lib/definitions";

interface CategoryItemProps {
  category: Category;
  upDisabled: boolean;
  downDisabled: boolean;
  onChange(wasUp: boolean): void;
}

const iconStyles: CSSProperties = {
  fontSize: "24px",
};

export function CategoryItem(props: CategoryItemProps) {
  return (
    <>
      {categoryToIcon[props.category]}
      <div style={{ color: categoryToColor[props.category], fontSize: "16px", fontWeight: "bold" }}>
        {props.category.toUpperCase()}
      </div>
      <UpOutlined
        style={{ ...iconStyles, opacity: props.upDisabled ? 0.2 : undefined, margin: "0 8px" }}
        onClick={() => props.onChange(true)}
      />
      <DownOutlined
        style={{ ...iconStyles, opacity: props.downDisabled ? 0.2 : undefined }}
        onClick={() => props.onChange(false)}
      />
    </>
  );
}
