import { CredentialsError, getAccessToken } from "./credentialsHandler";
import {
  API_ENDPOINT,
  CSE_API_KEY,
  CSE_ID,
  DOMAIN,
  LF_REFRESH_TARGET,
  LF_REFRESH_TARGET_DENY,
  Weekday,
} from "./definitions";
import { YearWeekDay } from "./models";
import * as localForage from "localforage";

export async function doFetch(
  httpMethod: "GET" | "POST" | "PUT" | "DELETE",
  path: string,
  onOK: (json: any) => void,
  onNotOK: (statusCode: number, json: any) => void,
  finallyCallback?: () => void,
  options?: {
    body?: any;
    customUrl?: boolean;
    noToken?: boolean;
  }
) {
  try {
    const response = await fetch(options?.customUrl ? path : `${API_ENDPOINT}${path}`, {
      headers: { "Content-Type": "application/json", Authorization: options?.noToken ? "" : await getAccessToken() },
      method: httpMethod,
      body: options?.body ? JSON.stringify(options?.body) : undefined,
    });
    if (response.ok) {
      try {
        onOK(await response.json());
      } catch {
        onOK(`${response.status} ${response.statusText}`);
      }
    } else {
      try {
        onNotOK(response.status, await response.json());
      } catch {
        onNotOK(response.status, response.statusText);
      }
    }
  } catch (error) {
    console.log(error);
    if (error instanceof CredentialsError) {
      onNotOK(400, error.message);
    } else {
      onNotOK(-1, "An error occured");
    }
  } finally {
    if (finallyCallback) {
      finallyCallback();
    }
  }
}

export function intToWeekday(int: number): Weekday {
  if (int === 0) {
    return "Mandag";
  } else if (int === 1) {
    return "Tirsdag";
  } else if (int === 2) {
    return "Onsdag";
  } else if (int === 3) {
    return "Torsdag";
  } else if (int === 4) {
    return "Fredag";
  } else if (int === 5) {
    return "Lørdag";
  } else {
    return "Søndag";
  }
}

async function getFirst10ImageUrls(searchQuery: string) {
  try {
    const response = await fetch(
      `https://www.googleapis.com/customsearch/v1?key=${CSE_API_KEY}&cx=${CSE_ID}&q=${searchQuery}`
    );

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    const responseAsJson = await response.json();

    if (responseAsJson.items && responseAsJson.items.length > 0) {
      return responseAsJson.items
        .map((item: any) => {
          if (item.pagemap && item.pagemap["cse_image"]) {
            return item.pagemap["cse_image"][0]?.src;
          } else {
            return "";
          }
        })
        .filter((url: string) => !!url);
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getDishImages(dishName: string) {
  const fetches = [
    getFirst10ImageUrls(dishName + " opskrift"),
    getFirst10ImageUrls(dishName + " mad"),
    getFirst10ImageUrls(dishName + " lækkert"),
  ];
  const promises = await Promise.all(fetches);
  return Array.from(new Set(promises.reduce((prev, curr) => prev.concat(curr), [])).values()) as string[];
}

export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getYearWeekDayRelative(relativeWeek: number): YearWeekDay {
  const d = new Date();
  d.setUTCDate(d.getUTCDate() + 7 * relativeWeek);
  return getYearWeekDay(d);
}

function getYearWeekDay(date: Date): YearWeekDay {
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  const day = date.getDay();
  const timestamp = date.getTime();
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  const weekNo = Math.ceil(((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);

  return {
    year: date.getUTCFullYear(),
    week: day === 0 ? weekNo - 1 : weekNo,
    day: day === 0 ? 6 : day - 1,
    timestamp: timestamp,
  };
}

export function getDateFromYearWeekDay(yearWeekDay: YearWeekDay, day: number) {
  const newDate = new Date(yearWeekDay.timestamp);
  newDate.setUTCDate(newDate.getUTCDate() + (day - yearWeekDay.day));

  const monthAsText = [
    "januar",
    "februar",
    "marts",
    "april",
    "maj",
    "juni",
    "juli",
    "august",
    "september",
    "oktober",
    "november",
    "december",
  ][newDate.getMonth()];

  return newDate.getDate() + ". " + monthAsText;
}

export function doFormalize(s: string) {
  return s
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

export function getStringFromYearWeekDay(yearWeekDay: YearWeekDay, replaceDay?: number) {
  return (
    "" +
    yearWeekDay.year +
    yearWeekDay.week.toString().padStart(2, "0") +
    (replaceDay !== undefined ? replaceDay : yearWeekDay.day)
  );
}

export async function forceRefresh() {
  window.location.reload();
}

export function checkVersionRefresh() {
  setTimeout(
    () =>
      doFetch(
        "GET",
        `https://api.${DOMAIN}/version/madplanen`,
        (response) => {
          localForage.getItem(LF_REFRESH_TARGET, async (_err, refreshTarget) =>
            localForage.getItem(LF_REFRESH_TARGET_DENY, (_err, refreshTargetDeny) => {
              if (response !== refreshTarget && response !== refreshTargetDeny) {
                if (response !== process.env.REACT_APP_VERSION) {
                  if (window.confirm("A newer version of the app is available. Update now?")) {
                    localForage.setItem(LF_REFRESH_TARGET, response).then(forceRefresh);
                  } else {
                    localForage.setItem(LF_REFRESH_TARGET_DENY, response);
                  }
                }
              } else {
                localForage.setItem(LF_REFRESH_TARGET, "");
              }
            })
          );
        },
        (err) => alert(`Fetching newest version: ${err}`),
        undefined,
        { customUrl: true, noToken: true }
      ),
    3000
  );
}

export function isOnMobile() {
  let check = false;
  (function (a) {
    if (
      // eslint-disable-next-line
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      // eslint-disable-next-line
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  return check;
}
