import { LoadingOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { DataContext, GroupContext } from "../../lib/contexts";
import { APIResources, Action } from "../../lib/definitions";
import {
  doFetch,
  getDateFromYearWeekDay,
  getStringFromYearWeekDay,
  getYearWeekDayRelative,
  isOnMobile,
} from "../../lib/functions";
import { Dish, WeekPlan, YearWeekDay } from "../../lib/models";
import { BLUE } from "../../lib/style_definitions";
import { DishPopup } from "../dishes/dishPopup";
import { ViewMessage } from "../general/viewMessage";
import { AddToShoppingListPopup } from "../shopping/addToShoppingListPopup";
import { DayContent } from "../weekOverview/dayContent";
import { WeekNumberSelector } from "../weekOverview/weekNumberSelector";
import { View } from "./view";
import * as localForage from "localforage";

const dayNumbers = [0, 1, 2, 3, 4, 5, 6];

export function WeekView() {
  const [yearWeekDay, setYearWeekDay] = useState<YearWeekDay>(getYearWeekDayRelative(0));
  const [selectedDish, setSelectedDish] = useState<Dish>();
  const [indexOfSelectedDish, setIndexOfSelectedDish] = useState(-1);
  const [selectedDay, setSelectedDay] = useState(-1);
  const [dishIds, setDishIds] = useState<string[]>([]);
  const [isUpdatingWeekPlan, setIsUpdatingWeekPlan] = useState(false);

  const history = useHistory();

  const { group, hasFetchedGroup } = useContext(GroupContext);
  const {
    weekPlan,
    refreshWeekPlan,
    isFetchingWeekPlan,
    dishes,
    folders,
    currentAction,
    setCurrentAction,
    setWeekPlan,
  } = useContext(DataContext);

  useEffect(() => {
    const weekNumber = new URLSearchParams(window.location.search).get("week") || "";
    if (weekNumber) {
      setYearWeekDay(getYearWeekDayRelative(Number.parseInt(weekNumber) - getYearWeekDayRelative(0).week));
    }
    localForage
      .getItem("weekViewScrollPos", (_err, val) =>
        document.getElementById("week-view-container")?.scrollTo(0, val as number)
      )
      .then(() => localForage.setItem("weekViewScrollPos", 0));
  }, []);

  useEffect(() => {
    setSelectedDish(dishes.find((d) => d._id === new URLSearchParams(window.location.search).get("selected-dish")));
  }, [dishes]);

  useEffect(() => {
    if (
      JSON.stringify(yearWeekDay) === JSON.stringify(getYearWeekDayRelative(0)) &&
      !(new URLSearchParams(window.location.search).get("week") || "")
    ) {
      setTimeout(
        () => document.getElementById(`day-${yearWeekDay.day - 1}`)?.scrollIntoView({ behavior: "smooth" }),
        50
      );
    } else {
      document.getElementById("week-view-container")?.scrollTo(0, 0);
    }
  }, [yearWeekDay]);

  const isOutdated = useCallback(
    (dayNumber: number) =>
      getYearWeekDayRelative(0).week === yearWeekDay.week
        ? getYearWeekDayRelative(0).day > dayNumber
        : getYearWeekDayRelative(0).week > yearWeekDay.week,
    [yearWeekDay]
  );

  const weeksDishIds = useMemo(() => {
    const ids: string[] = [];
    for (let i = 0; i < 7; i++) {
      if (!isOutdated(i)) {
        ids.push(...(weekPlan?.weekPlans[getStringFromYearWeekDay(yearWeekDay, i)] || []));
      }
    }
    return ids;
  }, [weekPlan, yearWeekDay, isOutdated]);

  function addDishToDay(day: number) {
    const ywdString = getStringFromYearWeekDay(yearWeekDay, day);
    const newDishes = (weekPlan?.weekPlans[getStringFromYearWeekDay({ ...yearWeekDay, day: day })] || []).concat([
      currentAction?.data._id,
    ]);

    // instant update
    const newWeekPlan: WeekPlan = JSON.parse(JSON.stringify(weekPlan));
    newWeekPlan.weekPlans[ywdString] = newDishes;
    setWeekPlan(newWeekPlan);

    doFetch(
      "PUT",
      APIResources.WeekPlans + `/${group!.shortId}`,
      refreshWeekPlan,
      () => alert("Opdatering af madplan: Der skete en fejl"),
      undefined,
      {
        body: {
          yearWeekDay: ywdString,
          dishes: newDishes,
        },
      }
    );
  }

  return (
    <View>
      {!group ? (
        <ViewMessage
          message="Du er ikke en del af nogen gruppe endnu."
          otherMessage="Gå i indstillinger for at lave eller deltage i en gruppe..."
          loading={!hasFetchedGroup}
        />
      ) : (
        <>
          <div
            style={{
              background: isOnMobile() ? "white" : undefined,
              padding: "10px 20px",
              width: "100%",
              boxShadow: isOnMobile() ? "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)" : undefined,
              display: "flex",
              alignItems: "center",
              zIndex: 100,
              position: "relative",
            }}
          >
            <WeekNumberSelector yearWeekDay={yearWeekDay} setYearWeekDay={setYearWeekDay} />
            {(isFetchingWeekPlan || isUpdatingWeekPlan) && (
              <div style={{ position: "absolute", right: "20px" }}>
                <LoadingOutlined style={{ color: BLUE, fontSize: "24px", marginTop: "4px" }} />
              </div>
            )}
          </div>
          <div
            style={{
              overflow: "auto",
              height: "calc(100% - 62px)",
              width: "100%",
              padding: "16px 24px 0 24px",
            }}
            id="week-view-container"
          >
            {dishes
              .filter((d) => weeksDishIds.includes(d._id))
              .reduce((prev, curr) => prev + curr.ingredients.length, 0) > 0 && (
              <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "16px" }}>
                <Button icon={<ShoppingCartOutlined />} size="large" onClick={() => setDishIds(weeksDishIds)}>
                  Overfør
                </Button>
              </div>
            )}
            <div style={{ rowGap: "36px", display: "grid" }}>
              {dayNumbers.map((n) => (
                <DayContent
                  id={`day-${n}`}
                  key={n}
                  dishIds={weekPlan?.weekPlans[getStringFromYearWeekDay(yearWeekDay, n)] || []}
                  yearWeekDay={yearWeekDay}
                  day={n}
                  isOutdated={isOutdated(n)}
                  isCurrentDay={
                    JSON.stringify(getYearWeekDayRelative(0)) === JSON.stringify({ ...yearWeekDay, day: n })
                  }
                  onDishClick={(dish, index) => {
                    if (currentAction?.action === "SELECT_DAY_FOR_DISH") {
                      addDishToDay(n);
                      setCurrentAction(undefined);
                    } else {
                      setSelectedDish(dish);
                      setSelectedDay(n);
                      setIndexOfSelectedDish(index);
                    }
                  }}
                  onAddDish={() => {
                    if (currentAction?.action === "SELECT_DAY_FOR_DISH") {
                      addDishToDay(n);
                      setCurrentAction(undefined);
                    } else {
                      localForage.setItem(
                        "weekViewScrollPos",
                        document.getElementById("week-view-container")?.scrollTop
                      );
                      setCurrentAction({ action: Action.SelectDishForDay, data: { ...yearWeekDay, day: n } });
                      history.push("/catalogue");
                    }
                  }}
                  onClear={() => {
                    const ywdString = getStringFromYearWeekDay(yearWeekDay, n);

                    // instant update
                    const newWeekPlan: WeekPlan = JSON.parse(JSON.stringify(weekPlan));
                    newWeekPlan.weekPlans[ywdString] = [];
                    setWeekPlan(newWeekPlan);

                    setIsUpdatingWeekPlan(true);
                    doFetch(
                      "PUT",
                      APIResources.WeekPlans + `/${group?.shortId}`,
                      refreshWeekPlan,
                      () => alert("Opdatering af madplan: Der skete en fejl"),
                      () => setIsUpdatingWeekPlan(false),
                      {
                        body: {
                          yearWeekDay: ywdString,
                          dishes: [],
                        },
                      }
                    );
                  }}
                  highlight={currentAction?.action === Action.SelectDayForDish}
                />
              ))}
            </div>
            <div style={{ marginBottom: "192px" }} />
          </div>
        </>
      )}
      {selectedDish && (
        <DishPopup
          dish={selectedDish}
          visible={!!selectedDish}
          onClose={() => setSelectedDish(undefined)}
          onAddToWeekPlan={() => {
            setSelectedDish(undefined);
            setCurrentAction({ action: Action.SelectDayForDish, data: selectedDish });
          }}
          onRemoveFromDay={
            isOutdated(selectedDay)
              ? undefined
              : {
                  dateString: getDateFromYearWeekDay(yearWeekDay, selectedDay),
                  onClick: () => {
                    setSelectedDish(undefined);
                    setIndexOfSelectedDish(-1);
                    setSelectedDay(-1);

                    const ywdString = getStringFromYearWeekDay(yearWeekDay, selectedDay);
                    const newDishes = [...weekPlan!.weekPlans[ywdString]].filter(
                      (_dish, index) => index !== indexOfSelectedDish
                    );

                    // instant update a
                    const newWeekPlan: WeekPlan = JSON.parse(JSON.stringify(weekPlan));
                    newWeekPlan.weekPlans[ywdString] = newDishes;
                    setWeekPlan(newWeekPlan);

                    setIsUpdatingWeekPlan(true);
                    doFetch(
                      "PUT",
                      APIResources.WeekPlans + `/${group?.shortId}`,
                      refreshWeekPlan,
                      () => alert("Opdatering af madplan: Der skete en fejl"),
                      () => setIsUpdatingWeekPlan(false),
                      {
                        body: {
                          yearWeekDay: ywdString,
                          dishes: newDishes,
                        },
                      }
                    );
                  },
                }
          }
          onEditSaveRedirect={`/week-plan?selected-dish=${selectedDish._id}`}
          folders={folders}
        />
      )}
      <AddToShoppingListPopup visible={dishIds.length > 0} onClose={() => setDishIds([])} dishIds={dishIds} />
    </View>
  );
}
