import { CalendarOutlined, DeleteOutlined, EditOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useMemo, useState } from "react";
import { useContext } from "react";
import { FcOpenedFolder } from "react-icons/fc";
import { useHistory } from "react-router-dom";
import { DataContext, GroupContext } from "../../lib/contexts";
import { Action, categoryToIcon, dishTypeToColor, PLACEHOLDER_URL } from "../../lib/definitions";
import { Dish, FolderI } from "../../lib/models";
import { RED } from "../../lib/style_definitions";
import { EcoLabel } from "../elements/ecoLabel";
import { Label } from "../elements/label";
import { MModal } from "../elements/mmodal";
import { AddToShoppingListPopup } from "../shopping/addToShoppingListPopup";
import { BsFolderPlus } from "react-icons/bs";

interface DishPopupProps {
  dish: Dish;
  visible: boolean;
  onAddToWeekPlan(): void;
  onClose(): void;
  onRemoveFromDay?: { dateString: string; onClick(): void };
  onEditSaveRedirect?: string;
  folders: FolderI[];
}

export function DishPopup(props: DishPopupProps) {
  const [showAddToShoppingListPopup, setShowAddToShoppingListPopup] = useState(false);

  const { group } = useContext(GroupContext);
  const { setCurrentAction, itemsMap } = useContext(DataContext);

  const history = useHistory();

  const inFolders = useMemo(() => props.folders.filter((folder) => folder.dishIds.includes(props.dish._id)), [
    props.dish,
    props.folders,
  ]);

  const showAmount = useMemo(
    () => props.dish.ingredients.reduce((hasMoreThanOne, ing) => hasMoreThanOne || ing.amount > 1, false),
    [props.dish.ingredients]
  );

  return (
    <>
      <MModal
        visible={props.visible}
        onClose={props.onClose}
        style={{ display: showAddToShoppingListPopup ? "none" : undefined }}
        title={props.dish.name}
        content={
          <div>
            <div
              style={{
                backgroundImage: `url(${props.dish.imageUrl}), url(${PLACEHOLDER_URL})`,
                width: "100%",
                height: "160px",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                borderRadius: "4px",
              }}
            />
            <div style={{ marginTop: "16px", display: "flex", alignItems: "center" }}>
              <Label text={props.dish.type} color={dishTypeToColor[props.dish.type || ""]} />
              <div style={{ color: "gray", marginLeft: "16px" }}>{props.dish.time + " min"}</div>
            </div>
            {props.dish.description && (
              <pre
                style={{
                  marginTop: "24px",
                  font: "unset",
                  whiteSpace: "pre-line",
                  maxHeight: "200px",
                  overflow: "auto",
                  // boxShadow: "inset 0px -15px 14px -18px grey",
                  fontSize: "14px",
                }}
                className="show-scroller"
              >
                {props.dish.description}
              </pre>
            )}
            {props.dish.ingredients.length !== 0 && (
              <div style={{ marginTop: "24px" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: `min-content ${showAmount ? "min-content" : ""} auto min-content`,
                    columnGap: 16,
                  }}
                >
                  {props.dish.ingredients
                    .map((ing) => ({ ...itemsMap[ing.id], amount: ing.amount }))
                    .sort((a, b) => a!.category.localeCompare(b!.category) * 10 + a!.name.localeCompare(b!.name))
                    .map((ing) => (
                      <React.Fragment key={ing._id}>
                        <div style={{ fontSize: "20px" }}>{categoryToIcon[ing!.category]}</div>
                        {showAmount && <div style={{ marginRight: -8 }}>{ing.amount > 1 ? ing.amount : ""}</div>}
                        <div>{ing.name}</div>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                          {ing!.isEcologic ? <EcoLabel size={1} style={{ marginBottom: 4 }} /> : <div />}
                        </div>
                      </React.Fragment>
                    ))}
                </div>
              </div>
            )}
            {inFolders.length > 0 && (
              <div style={{ margin: "16px 0", display: "flex" }}>
                <FcOpenedFolder style={{ fontSize: "24px", marginRight: "14px", marginLeft: "-2px" }} />
                <div style={{ display: "flex", columnGap: "8px", rowGap: "2px", flexWrap: "wrap" }}>
                  {inFolders.map((folder, i) => (
                    <div style={{ whiteSpace: "nowrap" }} key={folder._id}>
                      {folder.folderName + (i < inFolders.length - 1 ? ", " : "")}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div style={{ marginTop: props.onRemoveFromDay ? "8px" : "24px" }}>
              {props.onRemoveFromDay && (
                <div
                  style={{ marginBottom: "16px", textAlign: "end", color: RED }}
                  onClick={props.onRemoveFromDay.onClick}
                >
                  Fjern fra d. {props.onRemoveFromDay.dateString}
                  <DeleteOutlined style={{ marginLeft: "8px" }} />
                </div>
              )}
            </div>
          </div>
        }
        footer={
          <div>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "16px", columnGap: "8px" }}></div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button size="large" onClick={props.onClose}>
                Luk
              </Button>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  columnGap: "8px",
                }}
              >
                <Button
                  size="large"
                  icon={<BsFolderPlus style={{ marginBottom: "-3px" }} />}
                  onClick={() => {
                    setCurrentAction({ action: Action.SelectFolderForDish, data: props.dish });
                    props.onClose();
                    history.push("/catalogue");
                  }}
                />
                {props.dish.ingredients.length > 0 && (
                  <Button
                    size="large"
                    icon={<ShoppingCartOutlined />}
                    onClick={() => setShowAddToShoppingListPopup(true)}
                  />
                )}
                <Button size="large" icon={<CalendarOutlined />} onClick={props.onAddToWeekPlan} />
                {props.dish.groupShortId === group?.shortId && (
                  <Button
                    size="large"
                    type="primary"
                    onClick={() =>
                      history.push(
                        `/upsert-dish/${props.dish._id}${
                          props.onEditSaveRedirect ? `?redirect=${props.onEditSaveRedirect}` : ""
                        }`
                      )
                    }
                    icon={<EditOutlined />}
                  />
                )}
              </div>
            </div>
          </div>
        }
      />
      <AddToShoppingListPopup
        visible={showAddToShoppingListPopup}
        onClose={() => setShowAddToShoppingListPopup(false)}
        dishIds={[props.dish._id]}
      />
    </>
  );
}
