import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LoginScreen } from "./components/loginScreen";
import { Main } from "./components/main";
import { clearCredentials, getUserInfo } from "./lib/credentialsHandler";
import { UserInfo } from "./lib/models";
import * as localForage from "localforage";
import { LF_LAST_PATH } from "./lib/definitions";
import { WelcomeScreen } from "./components/views/welcomeScreen";
import { GRAY_BACKGROUND } from "./lib/style_definitions";
import { checkVersionRefresh } from "./lib/functions";
import { enableSmoothScrollingIOS } from "./lib/smoothScroller";

export function App() {
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [isGettingUserInfo, setIsGettingUserInfo] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [isFirstLoginTry, setIsFirstLoginTry] = useState(true);

  const history = useHistory();

  useEffect(() => {
    const viewport = document.querySelector("meta[name=viewport]");
    //@ts-ignore
    viewport.setAttribute("content", viewport.content + ", height=" + window.innerHeight);

    enableSmoothScrollingIOS();
  }, []);

  const updateUserInfo = useCallback(() => {
    setIsGettingUserInfo(true);
    getUserInfo()
      .then((userInfo) => {
        setUserInfo(userInfo);
        localForage.getItem(LF_LAST_PATH, (_err, val) => history.push(val + "" || "/week-plan"));
      })
      .catch(() => checkVersionRefresh())
      .finally(() => setIsGettingUserInfo(false));
  }, [history]);

  useEffect(updateUserInfo, [updateUserInfo]);

  return (
    <div style={{ height: "100vh", background: GRAY_BACKGROUND }}>
      {isGettingUserInfo && isFirstLoginTry && !isWorking ? (
        <WelcomeScreen />
      ) : (
        <>
          {!userInfo ? (
            <LoginScreen
              onLogin={() => {
                localForage.setItem(LF_LAST_PATH, "/week-plan");
                updateUserInfo();
              }}
              isWorking={isWorking}
              setIsWorking={setIsWorking}
            />
          ) : (
            <Main
              userInfo={userInfo}
              onLogout={async () => {
                setIsFirstLoginTry(false);
                await clearCredentials();
                setUserInfo(undefined);
              }}
            />
          )}
        </>
      )}
    </div>
  );
}
