import { PlusOutlined } from "@ant-design/icons";
import { Progress } from "antd";
import React from "react";
import { Category, categoryToIcon } from "../../lib/definitions";
import { BLUE } from "../../lib/style_definitions";
import { EcoLabel } from "../elements/ecoLabel";

interface ItemOptionProps {
  name: string;
  category: Category | "New" | "";
  disabled?: boolean;
  isEcologic?: boolean;
  popularity?: number;
  small?: boolean;
  onClick(): void;
}

export function ItemOption(props: ItemOptionProps) {
  return (
    <div
      style={{
        display: "flex",
        padding: props.small ? "8px 8px 8px 16px" : "12px 12px 12px 20px",
        borderBottom: "1px solid #dde1e5",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        color: props.disabled ? "#8e8e8e" : undefined,
      }}
      onClick={() => {
        if (props.disabled) {
          return;
        }
        props.onClick();
      }}
    >
      {props.disabled && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,0.1)",
            marginLeft: props.small ? -16 : -20,
          }}
        />
      )}

      <div style={{ display: "flex", alignItems: "center" }}>
        {props.category === "" ? (
          <div />
        ) : (
          <div style={{ fontSize: "24px", display: "flex", marginRight: "16px" }} onClick={props.onClick}>
            {props.category === "New" ? <PlusOutlined style={{ color: BLUE }} /> : categoryToIcon[props.category]}
          </div>
        )}
        <div
          style={{
            fontSize: "16px",
            wordBreak: "break-word",
            paddingTop: "1px",
          }}
        >
          {props.name}
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", paddingRight: "4px" }}>
        <EcoLabel size={1} style={{ visibility: props.isEcologic ? undefined : "hidden", marginRight: "4px" }} />
        {props.popularity !== undefined && (
          <Progress
            percent={props.popularity * 100}
            steps={5}
            size="small"
            showInfo={false}
            style={{ marginLeft: "8px" }}
          />
        )}
      </div>
    </div>
  );
}
