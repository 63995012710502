import React from "react";
import { LIGHT_GRAY } from "../../lib/style_definitions";
import { Spinner } from "./spinner";

interface ViewMessageProps {
  message: React.ReactNode;
  otherMessage?: React.ReactNode;
  loading?: boolean;
}

export function ViewMessage(props: ViewMessageProps) {
  return (
    <div
      style={{
        padding: "0 15%",
        textAlign: "center",
        top: "45%",
        transform: "translate(0,-50%)",
        position: "absolute",
        color: LIGHT_GRAY,
        width: "100%",
        fontWeight: "bold",
        fontSize: "20px",
      }}
    >
      {props.loading ? (
        <Spinner />
      ) : (
        <>
          <div>{typeof props.message === "string" ? props.message.toString().toUpperCase() : props.message}</div>
          {props.otherMessage && (
            <div style={{ marginTop: "16px" }}>
              {typeof props.otherMessage === "string"
                ? props.otherMessage.toString().toUpperCase()
                : props.otherMessage}
            </div>
          )}
        </>
      )}
    </div>
  );
}
