import { ClockCircleOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Radio } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DataContext, GroupContext } from "../../lib/contexts";
import { APIResources, Category, categoryToIcon } from "../../lib/definitions";
import { doFetch, doFormalize, getDishImages } from "../../lib/functions";
import { BLUE, GRAY_BACKGROUND } from "../../lib/style_definitions";
import { EcoLabel } from "../elements/ecoLabel";
import { MImg } from "../elements/mimg";
import { MInput } from "../elements/mInputs";
import { MModal } from "../elements/mmodal";
import { Segment } from "../elements/segment";
import { AddItemContent } from "../shopping/addItemContent";
import { CreateItemContent } from "../shopping/createItemContent";
import { View } from "./view";
import { FcOpenedFolder } from "react-icons/fc";
import { Dish } from "../../lib/models";
import { CircleButton } from "../elements/circleButton";

const NonFoodCategories: Category[] = [
  Category.Beauty,
  Category.Bought,
  Category.Electronics,
  Category.Garden,
  Category.Home,
  Category.Household,
  Category.Medicine,
  Category.Other,
  Category.Textile,
];

export function UpsertDish() {
  const [dishName, setDishName] = useState("");
  const [description, setDescription] = useState("");
  const [dishIngredients, setDishIngredients] = useState<Dish["ingredients"]>([]);
  const [hidden, setHidden] = useState(false);
  const [time, setTime] = useState(30);
  const [type, setType] = useState("Hovedret");
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [imageUrl, setImageUrl] = useState("");
  const [itemSearchValue, setItemSearchValue] = useState("");
  const [isUpsertingDish, setIsUpsertingDish] = useState(false);
  const [isDeletingDish, setIsDeletingDish] = useState(false);
  const [showCreateItemModal, setShowCreateItemModal] = useState(false);
  const [customImgUrl, setCustomImgUrl] = useState("");
  const [selectedFolders, setSelectedFolders] = useState<string[]>([]);

  const { items, itemsMap, refreshDishes, refreshFolders, dishes, folders } = useContext(DataContext);
  const { group } = useContext(GroupContext);

  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const itemInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    document.getElementById("upsert-dish")?.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (id) {
      const dish = dishes.find((d) => d._id === id);
      if (!dish) {
        return;
      }

      setDishName(dish?.name);
      setDescription(dish?.description);
      setDishIngredients(dish?.ingredients);
      setType(dish?.type);
      setTime(dish?.time);
      setImageUrl(dish?.imageUrl);
      setHidden(dish?.hidden);
      getDishImages(dish?.name).then(setImageUrls);
      setSelectedFolders(folders.filter((folder) => folder.dishIds.includes(dish._id)).map((folder) => folder._id));
    }
  }, [dishes, id, folders]);

  const ingredients = useMemo(() => dishIngredients.map((ing) => ({ ...itemsMap[ing.id], amount: ing.amount })), [
    itemsMap,
    dishIngredients,
  ]);

  const sortedItemOptions = useMemo(() => {
    const filteredItems = !itemSearchValue
      ? []
      : items
          .filter((i) => !NonFoodCategories.includes(i.category))
          .filter((i) =>
            doFormalize((i.isEcologic ? "økologisk " : "") + i.name).includes(doFormalize(itemSearchValue))
          )
          .filter((i) => !ingredients.find((ing) => ing.name === i.name));

    return filteredItems.sort((a, b) => {
      if (doFormalize(itemSearchValue) === doFormalize(a.name)) {
        return -1;
      } else if (doFormalize(itemSearchValue) === doFormalize(b.name)) {
        return 1;
      }

      if (a.popularity === b.popularity) {
        return a.name.localeCompare(b.name);
      }
      return (b.popularity || 0) - (a.popularity || 0);
    });
  }, [itemSearchValue, items, ingredients]);

  const itemIsOnList = useMemo(() => !!ingredients.find((i) => doFormalize(i.name) === doFormalize(itemSearchValue)), [
    ingredients,
    itemSearchValue,
  ]);

  function confirmItemName() {
    if (!itemSearchValue) {
      return;
    }

    const existingItem = sortedItemOptions.find((item) => doFormalize(item.name) === doFormalize(itemSearchValue));
    if (existingItem) {
      setDishIngredients((ings) => ings.concat({ id: existingItem._id, amount: 1 }));
    } else if (ingredients.find((i) => doFormalize(i.name) === doFormalize(itemSearchValue))) {
      alert(`${itemSearchValue} er allerede på din indkøbsliste`);
    } else {
      setShowCreateItemModal(true);
    }
  }

  function handleIngredientAmountClick(id: string, amount?: number) {
    setDishIngredients((ings) => ings.map((i) => (i.id === id ? { ...i, amount: amount ?? i.amount + 1 } : i)));
  }

  return (
    <View scrollable header={id ? "Opdater ret" : "Ny ret"} goBack scrollableContainerId="upsert-dish">
      <Segment title="Generelt">
        <MInput
          size="large"
          placeholder="N&zwnj;avn på retten..."
          value={dishName}
          onChange={(e) => setDishName(e.target.value)}
          onBlur={async () => {
            if (!dishName) {
              return;
            }
            setImageUrls(await getDishImages(dishName));
          }}
        />
        <TextArea
          placeholder="Beskrivelse..."
          style={{ marginTop: "16px", fontSize: "18px" }}
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <div style={{ marginTop: "16px", display: "flex", alignItems: "center" }}>
          <ClockCircleOutlined style={{ marginRight: "16px", fontSize: "24px" }} />
          <Radio.Group size="large" value={time} onChange={(e) => setTime(e.target.value)}>
            <Radio.Button value={5}>5</Radio.Button>
            <Radio.Button value={15}>15</Radio.Button>
            <Radio.Button value={30}>30</Radio.Button>
            <Radio.Button value={45}>45</Radio.Button>
            <Radio.Button value={60}>60</Radio.Button>
          </Radio.Group>
        </div>
        {folders.length > 1 && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <FcOpenedFolder style={{ fontSize: "36px", marginLeft: "-4px", marginTop: "16px" }} />
            <div
              style={{
                marginTop: "16px",
                display: "flex",
                alignItems: "center",
                overflowX: "auto",
                columnGap: "8px",
                paddingLeft: "10px",
                marginRight: "-24px",
              }}
            >
              {folders
                .filter((f) => f.folderName !== "Alle")
                .map((folder) => (
                  <Button
                    key={folder.folderName}
                    size="large"
                    type={selectedFolders.includes(folder._id) ? "primary" : undefined}
                    onClick={() =>
                      setSelectedFolders((fs) => {
                        if (fs.includes(folder._id)) {
                          return fs.filter((f) => f !== folder._id);
                        } else {
                          return fs.concat(folder._id);
                        }
                      })
                    }
                  >
                    {folder.folderName}
                  </Button>
                ))}
              <div style={{ marginLeft: "16px", color: "white" }}>.</div>
            </div>
          </div>
        )}
        <div
          style={{
            marginTop: "16px",
            display: "flex",
            alignItems: "center",
            overflowX: "auto",
            width: "100vw",
            columnGap: "8px",
            paddingLeft: "24px",
            marginLeft: "-24px",
          }}
        >
          <Button size="large" type={type === "Forret" ? "primary" : undefined} onClick={() => setType("Forret")}>
            Forret
          </Button>
          <Button size="large" type={type === "Hovedret" ? "primary" : undefined} onClick={() => setType("Hovedret")}>
            Hovedret
          </Button>
          <Button size="large" type={type === "Dessert" ? "primary" : undefined} onClick={() => setType("Dessert")}>
            Dessert
          </Button>
          <Button size="large" type={type === "Morgenmad" ? "primary" : undefined} onClick={() => setType("Morgenmad")}>
            Morgenmad
          </Button>
          <Button
            size="large"
            type={type === "Middagsmad" ? "primary" : undefined}
            onClick={() => setType("Middagsmad")}
          >
            Middagsmad
          </Button>
          <div style={{ marginLeft: "16px", color: "white" }}>.</div>
        </div>
      </Segment>
      <Segment title="Ingredienser">
        <div style={{ display: "flex", alignItems: "center" }}>
          <SearchOutlined style={{ marginRight: "8px", fontSize: "20px" }} />
          <MInput
            size="large"
            placeholder="Søg varer..."
            innerref={itemInputRef}
            allowClear
            value={itemSearchValue}
            onChange={(e) => setItemSearchValue(e.target.value)}
            suffix={
              <PlusOutlined
                style={{
                  color: BLUE,
                  fontSize: "24px",
                  opacity: itemSearchValue ? 1 : 0.3,
                  marginLeft: "8px",
                }}
                onClick={confirmItemName}
              />
            }
            onPressEnter={confirmItemName}
            className="new-item-input"
          />
        </div>
        <div
          style={{
            background: GRAY_BACKGROUND,
            borderRadius: "4px",
            height: "220px",
            marginTop: "16px",
            overflow: "auto",
          }}
        >
          <AddItemContent
            onNewItem={() => setShowCreateItemModal(true)}
            small
            itemsOnList={dishIngredients.map((ing) => ({ _id: ing.id, amount: ing.amount }))}
            sortedItemOptions={sortedItemOptions}
            currentItemName={itemSearchValue}
            itemIsOnList={itemIsOnList}
            isIngredients
            addItemToList={(item) => {
              itemInputRef.current?.focus();
              setDishIngredients((ings) => ings.concat({ id: item._id, amount: 1 }));
              setItemSearchValue("");
            }}
          />
        </div>
        <div style={{ marginTop: "24px" }}>
          {dishIngredients.length === 0 ? (
            <div style={{ textAlign: "center" }}>Ingen ingredienser tilføjet...</div>
          ) : (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "min-content min-content auto min-content min-content",
                rowGap: "8px",
                columnGap: "16px",
                alignItems: "center",
                margin: "0px 8px",
              }}
            >
              {ingredients
                .sort((a, b) => a.category.localeCompare(b.category) * 10 + a.name.localeCompare(b.name))
                .map((ing, i) => (
                  <React.Fragment key={ing.name}>
                    <div style={{ marginBottom: "-6px", fontSize: "18px" }}>{categoryToIcon[ing.category]}</div>
                    <CircleButton
                      onClick={() => handleIngredientAmountClick(ing._id)}
                      onLongPress={() => handleIngredientAmountClick(ing._id, 1)}
                      text={ing.amount}
                    />
                    <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{ing.name}</div>
                    {ing.isEcologic ? <EcoLabel size={1} /> : <div />}
                    <DeleteOutlined
                      onClick={() => setDishIngredients((ings) => ings.filter((i) => i.id !== ing._id))}
                      style={{ fontSize: 18 }}
                    />
                    {i !== dishIngredients.length - 1 && (
                      <div style={{ gridColumn: "span 5", borderBottom: `1px solid lightgray`, margin: "-8px 0px" }} />
                    )}
                  </React.Fragment>
                ))}
            </div>
          )}
        </div>
      </Segment>
      <Segment title="Billede">
        {imageUrls.length === 0 ? (
          <div style={{ textAlign: "center" }}>Angiv rettens navn for at få billedforslag...</div>
        ) : (
          <div>
            <div style={{ borderBottom: "2px solid #d9d9d9", margin: "0 -4px" }}></div>
            <div
              style={{
                display: "grid",
                gridAutoRows: "min-content",
                gridTemplateColumns: "1fr 1fr",
                columnGap: "12px",
                rowGap: "12px",
                height: "420px",
                overflow: "auto",
              }}
            >
              {imageUrls.map((u, i) => (
                <div
                  key={u + i}
                  style={{
                    border: `2px solid ${!customImgUrl && imageUrl === u ? BLUE : "white"}`,
                  }}
                  onClick={() => setImageUrl(u)}
                >
                  <MImg url={u} extraClassName="mimg__select" />
                </div>
              ))}
            </div>
            <div style={{ borderBottom: "2px solid #d9d9d9", margin: "0 -8px" }}></div>
            <div style={{ textAlign: "center", margin: "16px" }}>Eller</div>
            <MInput
              size="large"
              placeholder="Egen billede-URL..."
              value={customImgUrl}
              allowClear
              onChange={(e) => setCustomImgUrl(e.target.value)}
            />
            {customImgUrl && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    border: `2px solid ${BLUE}`,
                    marginTop: "16px",
                    width: "50%",
                    height: "50%",
                  }}
                >
                  <MImg url={customImgUrl} extraClassName="mimg__select" />
                </div>
              </div>
            )}
          </div>
        )}
      </Segment>
      <div style={{ display: "flex", justifyContent: "center", margin: "0 48px" }}>
        <Button
          type="primary"
          size="large"
          disabled={!dishName || !imageUrl}
          loading={isUpsertingDish}
          onClick={
            id
              ? () => {
                  setIsUpsertingDish(true);
                  doFetch(
                    "PUT",
                    APIResources.Dishes + `/${id}`,
                    () => {
                      refreshDishes();
                      refreshFolders();
                      const redirect = new URLSearchParams(window.location.search).get("redirect") || "";
                      if (redirect) {
                        history.push(redirect);
                      } else {
                        history.push(`/catalogue?selected-dish=${id}`);
                      }
                    },
                    () => alert("Opdatering af ret: Der skete en fejl"),
                    () => setIsUpsertingDish(false),
                    {
                      body: {
                        name: dishName.trim(),
                        description: description.trim(),
                        time: time,
                        type: type,
                        ingredients: dishIngredients,
                        imageUrl: customImgUrl || imageUrl,
                        hidden: hidden,
                        inFolders: selectedFolders,
                      },
                    }
                  );
                }
              : () => {
                  setIsUpsertingDish(true);
                  doFetch(
                    "POST",
                    APIResources.Dishes,
                    () => {
                      refreshDishes();
                      refreshFolders();
                      history.push("/catalogue");
                    },
                    () => alert("Oprettelse af ret: Der skete en fejl"),
                    () => setIsUpsertingDish(false),
                    {
                      body: {
                        groupShortId: group?.shortId,
                        name: dishName,
                        description: description,
                        time: time,
                        type: type,
                        ingredients: dishIngredients,
                        imageUrl: imageUrl,
                        hidden: hidden,
                        inFolders: selectedFolders,
                      },
                    }
                  );
                }
          }
        >
          {id ? "Opdater" : "Opret"}
        </Button>
      </div>
      {id && (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "48px" }}>
          <Button
            type="primary"
            size="large"
            loading={isDeletingDish}
            danger
            onClick={() => {
              if (window.confirm("Er du sikker på at du vil slette retten?")) {
                setIsDeletingDish(true);
                doFetch(
                  "DELETE",
                  APIResources.Dishes + `/${id}`,
                  () => {
                    refreshDishes();
                    refreshFolders();
                    history.push("/catalogue");
                  },
                  () => alert("Sletning af ret: Der skete en fejl"),
                  () => setIsDeletingDish(false)
                );
              }
            }}
          >
            Slet
          </Button>
        </div>
      )}
      <div style={{ marginBottom: "192px" }} />
      <MModal
        visible={showCreateItemModal}
        onClose={() => {
          setShowCreateItemModal(false);
          itemInputRef.current?.focus();
        }}
        title="Opret ny vare"
        content={
          <CreateItemContent
            onClose={() => {
              setShowCreateItemModal(false);
              itemInputRef.current?.focus();
            }}
            onFinish={(itemId) => {
              itemInputRef.current?.focus();
              setShowCreateItemModal(false);
              setDishIngredients((ings) => ings.concat({ id: itemId, amount: 1 }));
              setItemSearchValue("");
              setShowCreateItemModal(false);
            }}
            initialItemName={itemSearchValue}
          />
        }
      />
    </View>
  );
}
